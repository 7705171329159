<template>
  <div>
    <!-- Invoice Data Table -->
    <v-row align="start">
      <v-col cols="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" >
              <v-col cols="12" sm="12" md="3" >
                <h5>Invoice</h5>
              </v-col>
              <v-col cols="12" sm="12" md="1" class="d-flex justify-end">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="blue"
                  @click="openSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="1" class="d-flex justify-end">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="success"
                  @click="resetData"
                >
                  <v-icon dark>
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="5" sm="12" md="2" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.invoice)"
                >
                  Crear Invoice
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table
              dense
              :headers="headers.invoice"
              :items="invoice_records"
              :items-per-page="20"
              multi-sort
              item-key="invoice_folio"
              class=" rounded-xl"
              :search="invoiceSearch"
              :loading="loadingInvoiceTable"
              loading-text="Loading... Please wait"
              sort-by="invoice_folioId.id"
              sort-desc
              hide-default-footer
            >
              <template v-slot:item.invoice_folioId.id="{ item }">
                <tr>
                  <td 
                    class="pl-4 blue-grey--text text--darken-1"
                    >
                      {{ `FACT-${item.invoice_folioId.id}` }}
                    </td>
                </tr>
              </template>
              <template v-slot:item.invoice_folioId.balance_due="{ item }">
                <tr class="d-flex justify-end">
                  <td class="text-caption blue-grey--text text--darken-1 mx-1">
                    <span >{{`${item.invoice_folioId.balance_due ? convertToMoney(item.invoice_folioId.balance_due): 0}`}}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:item.actions="{ item, isMobile }">
                <div v-if="!isMobile">
                  <v-icon color="warning" small class="px-1" @click="editItem(item, boards.invoice)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="primary" small class="px-1" @click="openCreateInvoiceDetailsForm(item, 'invoiceDetail')">
                    mdi-text-box-search
                  </v-icon>
                  <v-icon small color="warning" v-if="item.noCalendar.length > 0" @click="invoicePdf(item, true)">
                    mdi-calendar-remove-outline
                  </v-icon>
                  <v-icon small color="primary-red" class="px-1" v-if="item.invoice_details.length === 0">
                    mdi-calendar-remove-outline
                  </v-icon>
                  <v-icon small color="red" class="px-1" v-if="item.noCalendar.length > 0" @click="deleteInvoiceWithFolio(item)">
                    mdi-delete-variant
                  </v-icon>
                  <v-icon small color="red" class="px-1" v-if="item.invoice_details.length === 0" @click="deleteInvoiceWithFolio(item)">
                    mdi-delete-variant
                  </v-icon>
                </div>
                 <div v-if="isMobile">
                  <v-icon color="warning" class="px-1" @click="editItem(item, boards.invoice)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="primary" class="px-1" @click="openCreateInvoiceDetailsForm(item, 'invoiceDetail')">
                    mdi-text-box-search
                  </v-icon>
                  <v-icon color="warning" v-if="item.noCalendar.length > 0" @click="invoicePdf(item, true)">
                    mdi-calendar-remove-outline
                  </v-icon>
                  <v-icon color="primary-red" v-if="item.invoice_details.length === 0">
                    mdi-calendar-remove-outline
                  </v-icon>
                  <v-icon small color="red" class="px-1" v-if="item.noCalendar.length > 0">
                    mdi-delete-variant
                  </v-icon>
                  <v-icon small color="red" class="px-1" v-if="item.invoice_details.length === 0">
                    mdi-delete-variant
                  </v-icon>
                </div>
              </template>
            
            </v-data-table>
            <v-pagination
              v-model="currentPage"
              :length="totalPages"
              total-visible="8"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="gotoPage"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

   
    <!-- Invoice Form -->
    <v-row justify="center" >
      
        <v-dialog  v-model="dialogInvoice" persistent max-width="700px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title class="py-2">
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Invoice FACT-${createInvoiceForm.id}` : `Crear Invoice` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-form ref="createInvoiceForm" v-model="valid" lazy-validation >
              <v-card-text class="mt-2">
                <v-container>
                  <!-- Linea Reservas -->
                  <v-row dense>
                    <v-col cols="12" md=6>                   
                      <v-autocomplete
                        v-if="!btnEdithVisible"
                        class="text-caption"
                        v-model="createInvoiceForm.folio"
                        item-value="id"
                        dense
                        :items="reservations"
                        :item-text="itemTextfolio"
                        label="Reservas"
                        placeholder="Escojer una reserva"
                        required
                        :rules="[createFormRules.required]"
                        clearable
                        @change="verificarCustomer"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-if="btnEdithVisible"
                        class="text-caption"
                        v-model="createInvoiceForm.reservation"
                        item-value="id"
                        dense
                        disabled
                        :items="typeReservation ? allReservation: reservations "
                        :item-text="itemTextfolio"
                        label="Reservas"
                        placeholder="Escojer una reserva"
                        required
                        :rules="[createFormRules.required]"
                        clearable
                        @change="verificarCustomer"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- linea datos factura -->
                  <v-row dense align="center" >
                     <v-col cols="12" md="3">
                      <v-menu
                        :key="reservationDateKey1" 
                        v-model="menuStarDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        required
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :key="reservationDateKey" 
                            v-model="createInvoiceForm.date"
                            label="Fecha"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :key="reservationDateKey2" 
                          v-model="createInvoiceForm.date"
                          @input="menuStarDate = false"
                          :rules="[createFormRules.required]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                    <v-menu
                        v-model="menuFinalDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :rules="[createFormRules.required]"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="createInvoiceForm.date_due"
                            label="Vencimiento"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createInvoiceForm.date_due"
                          @input="menuFinalDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-3">
                      <v-autocomplete
                        v-if="!btnEdithVisible"
                        class="text-caption"                        
                        v-model="createInvoiceForm.bill_state"
                        item-value="id"
                        dense
                        :items="billStateOptions"
                        item-text="value"
                        label="Estado Factura"
                        :rules="[createFormRules.required]"
                        disabled
                        >
                      </v-autocomplete>
                      <v-autocomplete
                        v-if="btnEdithVisible"
                        class="text-caption"                        
                        v-model="createInvoiceForm.bill_state"
                        item-value="id"
                        dense
                        :items="billStateOptions"
                        item-text="value"
                        label="Estado Factura"
                        :rules="[createFormRules.required]"
                        >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col xs="12" md=6>                   
                      <v-autocomplete
                        v-if="!typeReservation"
                        class="text-caption"
                        v-model="createInvoiceForm.bill_to"
                        item-value="id"
                        dense
                        :items="customers"
                        :item-text="itemTextCustomer"
                        label="Nombre Cliente Factura"
                        :rules="[createFormRules.required]"
                        clearable
                        :value="createInvoiceForm.bill_to"
                      ></v-autocomplete>
                       <v-text-field
                        v-if="typeReservation"
                        class="text-caption"
                        v-model="createInvoiceForm.lead_traveler_name"
                        dense
                        disabled
                        label="Nombre Cliente Factura"
                      ></v-text-field>
                  </v-col>
                   <v-col xs="12" md="4">
                      <v-fab-transition>
                         <v-btn v-if="!this.createInvoiceForm.bill_to"
                            fab
                            dark
                            small
                            color="indigo"
                            @click="openCustomerSimpleForm"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                      </v-fab-transition>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.invoice)">
                Cancelar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.invoice)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.invoice)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      
    </v-row>

    <!-- Invoice Detail Table -->
    <div class="text-center">
      <v-dialog
        persistent
        v-model="dialogInfoInvoice"
        width="800"
      >
        <v-card :disabled="loadingTableInfoReservas">
          <v-card-title class="text-h5 grey lighten-2">
            Detalles Servicios de Factura
          </v-card-title>

          <v-card-text>
            <v-data-table          
              dense
              :headers="headersInfoReserva"
              :items="invoiceDetail"
              :items-per-page="15"
              multi-sort
              item-key="id"
              class="rounded-xl"
              :loading="loadingTableInfoReservas"
              :disabled="loadingTableInfoReservas"
              loading-text="Loading... Please wait"
              single-expand
              >
                
                <template v-slot:item="{ item, isExpanded, expand, isMobile, headers}">
                  <tr v-if="!isMobile">
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1">{{ `FACT-${item.invoice_folioId.id}` }}</td>
                    <td class="text-custom text-center text-caption blue-grey--text text--darken-1">{{ `${item.reservationId.booking_reference}` }}</td>
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1">{{ `${item.invoice_folioId.date}` }}</td>
                    <td @click="expand(!isExpanded)" class="text-caption text-center blue-grey--text text--darken-1">{{ `${item.invoice_folioId.bill_state}`}}</td>
                    <td @click="expand(!isExpanded)" class="text-center text-caption blue-grey--text text--darken-1">{{ `${item.invoice_folioId.balance_due}` }}</td>
                    
                    <td class="text-center pr-1 d-flex align-center justify-center">                         
                       <v-icon color="green" class="mr-2" v-if="item.invoice_folioId.bill_state == 'PAGADA' && item.invoice_details.length > 0">
                        mdi-check-all 
                      </v-icon>
                      <!-- <v-icon color="warning" small class="mr-2" @click="editarFactura(item)">
                        mdi-pencil
                      </v-icon> -->
                       <v-icon color="primary" class="mr-2" @click="openCreateDetail(item)">
                        mdi-playlist-plus
                      </v-icon>
                      <v-btn  icon >
                        <v-icon  color="blue" @click="invoicePdf(item, true)">
                          mdi-receipt-text
                        </v-icon>
                      </v-btn>
                       <v-btn icon >
                        <v-icon color="green" @click="dialogSendEmail(item)">
                          mdi-email-fast-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="isMobile">
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                      <p class="ma-0">
                        <strong>{{headers[0].text}}</strong>
                      </p>
                      {{ `FACT-${item.invoice_folioId.id}` }}
                    </td>
                    <td class="text-custom text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                      <p class="ma-0">
                        <strong>{{headers[1].text}}</strong>
                      </p>
                      {{ `${item.reservationId.booking_reference}` }}
                    </td>
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                      <p class="ma-0">
                        <strong>{{headers[2].text}}</strong>
                      </p>
                      {{ `${item.invoice_folioId.date}` }}
                    </td>
                    <td @click="expand(!isExpanded)" class="text-caption text-center blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                      <p class="ma-0">
                        <strong>{{headers[3].text}}</strong>
                      </p>
                      {{ `${item.invoice_folioId.bill_state}`}}
                    </td>
                    <td @click="expand(!isExpanded)" class="text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                      <p class="ma-0">
                        <strong>{{headers[4].text}}</strong>
                      </p>
                      {{ `${item.invoice_folioId.balance_due}` }}
                    </td>
                    
                    <td class="text-center pr-1 d-flex align-center justify-center">                         
                       <v-icon color="green" class="mr-2" v-if="item.invoice_folioId.bill_state == 'PAGADA' && item.invoice_details.length > 0">
                        mdi-check-all 
                      </v-icon>
                      <!-- <v-icon color="warning" small class="mr-2" @click="editarFactura(item)">
                        mdi-pencil
                      </v-icon> -->
                       <v-icon color="primary" class="mr-2" @click="openCreateDetail(item)">
                        mdi-playlist-plus
                      </v-icon>
                      <v-btn dark icon >
                        <v-icon dark color="blue" @click="invoicePdf(item, true)">
                          mdi-receipt
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr> 
                </template>
                <!-- parte expandible de la tabla con los detalles -->
                <template v-slot:expanded-item="{ headers, item, isMobile }">
                  <td :colspan="headers.length">
                    <v-card elevation="0" v-if="!isMobile">
                      <v-list>
                        <template v-for="detalles in item.invoice_details" >
                         
                          <v-list-item :key="detalles.id">
                            <v-list-item-avatar >
                              <v-icon
                                color="white"
                                v-if="detalles.service_type == 'IN'"
                                small
                                :class="[
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ? 'orange': 
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' ? 'primary': 
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' 
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' ? 'green' :
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr' ? 'brown lighten-1' :
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? 'purple' :
                                  'blue lighten-1' ]"
                                dark
                              >
                                mdi-folder
                              </v-icon>
                              <v-icon
                                v-else
                                color="white"  
                                small
                                class="red lighten-1"
                                dark
                              >
                                mdi-folder
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title >
                                <span class="text-center text-overline blue-grey--text text--darken-1">
                                  {{item.reservationId.lead_traveler_name ? item.reservationId.lead_traveler_name : detalles.traveler_names ? detalles.traveler_names : item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }}
                                  
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <div v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                  <span class="mr-2"><strong>Start Date:</strong>{{` ${detalles.travel_date}`}}</span>
                                  <span class="mr-2"><strong>End Date:</strong>{{` ${detalles.departure_date}`}}</span>
                                </div>
                                <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">

                                </div>
                                <div v-else>
                                  <span class="mr-2" ><strong>Date:</strong>{{` ${detalles.travel_date ? detalles.travel_date: detalles.departure_date}`}}</span>
                                  <span class="mr-2"><strong>No. Pax:</strong>{{` ${detalles.pax} Adultos ${detalles.pax_kids ? detalles.pax_kids + ' Niños': '' }` }}</span> <br>
                                </div>
                            
                                <div class="mr-2" v-if="detalles.service_type == 'IN'">
                                  <div v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV'">
                                    <strong class="mr-3 orange--text" >ATV</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip'">
                                    <strong class="mr-3 green--text" >Zip Line</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'">
                                    <strong class="mr-3 green--text" >Llanos cortes</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio'">
                                    <strong class="mr-3 green--text" >Rio Celeste</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'">
                                    <strong class="mr-3 green--text" >{{ detalles.product_invoice_detailId.short_name}}</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra'">
                                    <strong class="mr-3 primary--text" >Traslado</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                    <strong class="mr-3 purple--text" >Scooter</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">
                                    <strong class="mr-3 brown--text text--lighten-1" >{{detalles.product_invoice_detailId.short_name}}</strong>
                                  </div>
                                  <div v-else>
                                    <strong>Pick up Location:</strong>{{` ${detalles.pick_up_location}`}}
                                    <strong>Drop Off:</strong>{{` ${detalles.drop_off_location}`}}
                                  </div>
                                </div> 
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Drop Location:</strong>{{` ${ detalles.drop_off_location + ' - LIB ATO' }`}}</span> <br v-if="detalles.service_type == 'OUT'">
                                  
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Time:</strong>{{` ${ detalles.departure_time }`}}</span> 
                                <div class="mr-2 d-flex" v-if="detalles.service_type == 'IN'">
                                  <div 
                                    v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ||
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'  
                                  
                                  ">
                                    <span class="mr-2" ><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                    <span class="mr-2"><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    <strong>Moto:</strong>{{`  ${detalles.arrival_flight_no} `}} 
                                    <strong>Note:</strong>{{`  ${detalles.special_requirements}`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">
                                    <strong>Moto:</strong>{{`  ${detalles.arrival_flight_no} `}}
                                  </div>
                                  <div v-else>
                                    <span class="mr-2" v-if="detalles.service_type == 'IN'"><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    <strong>Flight:</strong>{{` ${ detalles.arrival_airline + ' - ' + detalles.arrival_flight_no}`}}
                                  </div>
                                </div>
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Flight</strong>{{` ${ detalles.departure_airline + ' - ' + detalles.departure_flight_no }`}}</span>
                                  
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-card-actions>
                              
                              <v-icon color="warning" small class="mr-2" @click="editRowInvoiceDetail(detalles, item)">
                                mdi-pencil
                              </v-icon>
                              <v-icon color="red" class="mr-2" @click="openDelete(detalles, 'invoiceDetail', item)">
                                mdi-delete
                              </v-icon>
                              <v-tooltip top v-if="detalles.calendar_operation">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn 
                                  dark
                                  icon
                                  :href="detalles.calendar_operationId.htmlLink"
                                  target="_blank"
                                  >
                                    <v-icon                                       
                                      color="green" 
                                      v-bind="attrs"
                                      v-on="on" 
                                    >
                                      mdi-calendar-multiple-check
                                    </v-icon>                                  
                                  </v-btn>
                                </template>
                                <span>Evento con calendario</span>
                              </v-tooltip>
                              <v-tooltip top v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) != 'Otr'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn 
                                    dark
                                    icon                                    
                                    >
                                    <v-icon 
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on" 
                                      @click="createEventCalendar(detalles, item, 'create')">
                                      mdi-calendar-question
                                    </v-icon> 
                                  </v-btn>               
                                </template>
                                <span>**Evento sin Calendario**</span>
                              </v-tooltip>
                              <v-icon 
                                v-if="detalles.calendar_operation"
                                color="blue" 
                                class="mr-2" 
                                @click="createEventCalendar(detalles, item, 'update')">
                                mdi-calendar-refresh
                              </v-icon>
                               <v-icon 
                                v-if="detalles.calendar_operation"
                                color="red" 
                                class="mr-2" 
                                @click="createEventCalendar(detalles, item, 'delete')">
                                mdi-calendar-remove
                              </v-icon>

                            </v-card-actions>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                    <v-card elevation="0" v-if="isMobile">
                      <v-list>
                        <template v-for="detalles in item.invoice_details" >
                         
                          <v-list-item :key="detalles.id">

                            <v-list-item-content>
                              <v-list-item-title >
                                <v-list-item-avatar height="20px" width="20px" min-width="20px" class="ml-0">
                                  <v-icon
                                    color="white"
                                    v-if="detalles.service_type == 'IN'"
                                    small
                                    :class="[
                                      detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ? 'orange': 
                                      detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' ? 'primary': 
                                      detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' 
                                        || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'
                                        || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'
                                        || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' ? 'green' :
                                        detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr' ? 'brown lighten-1' :
                                        detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? 'purple' :
                                      'blue lighten-1' ]"
                                    dark
                                  >
                                    mdi-folder
                                  </v-icon>
                                  <!-- <v-icon
                                    v-else
                                    color="white"  
                                    small
                                    class="red lighten-1"
                                    dark
                                  >
                                    mdi-folder
                                  </v-icon> -->
                                </v-list-item-avatar>
                                <span class="text-center text-overline blue-grey--text text--darken-1">
                                  {{item.reservationId.lead_traveler_name ? item.reservationId.lead_traveler_name : detalles.traveler_names ? detalles.traveler_names : item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }}
                                  
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <div v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                  <span class="mr-2"><strong>Start Date:</strong>{{` ${detalles.travel_date}`}}</span>
                                  <span class="mr-2"><strong>End Date:</strong>{{` ${detalles.departure_date}`}}</span>
                                </div>
                                <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">

                                </div>
                                <div v-else>
                                  <span class="mr-2" ><strong>Date:</strong>{{` ${detalles.travel_date ? detalles.travel_date: detalles.departure_date}`}}</span>
                                  <span class="mr-2"><strong>No. Pax:</strong>{{` ${detalles.pax} Adultos ${detalles.pax_kids ? detalles.pax_kids + ' Niños': '' }` }}</span> <br>
                                </div>
                            
                                <div class="mr-2" v-if="detalles.service_type == 'IN'">
                                  <div v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV'" class="d-flex flex-column">
                                    <div>
                                      <strong class="mr-3 orange--text" >ATV</strong>
                                      <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    </div>
                                    <div>
                                      <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                    </div>
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip'">
                                    <strong class="mr-3 green--text" >Zip Line</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'">
                                    <strong class="mr-3 green--text" >Llanos cortes</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio'">
                                    <strong class="mr-3 green--text" >Rio Celeste</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'">
                                    <strong class="mr-3 green--text" >{{ detalles.product_invoice_detailId.short_name}}</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra'">
                                    <strong class="mr-3 primary--text" >Traslado</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                    <strong class="mr-3 purple--text" >Scooter</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">
                                    <strong class="mr-3 brown--text text--lighten-1" >{{detalles.product_invoice_detailId.short_name}}</strong>
                                  </div>
                                  <div v-else>
                                    <strong>Pick up Location:</strong>{{` ${ 'LIB ATO - ' + detalles.drop_off_location }`}}
                                  </div>
                                </div> 
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Drop Location:</strong>{{` ${ detalles.drop_off_location + ' - LIB ATO' }`}}</span> <br v-if="detalles.service_type == 'OUT'">
                                  
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Time:</strong>{{` ${ detalles.departure_time }`}}</span> 
                                <div class="mr-2 d-flex" v-if="detalles.service_type == 'IN'">
                                  <div 
                                    v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ||
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'  
                                  
                                  ">
                                    <span class="mr-2" ><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'">
                                    <span class="mr-2"><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    <strong>Moto:</strong>{{`  ${detalles.arrival_flight_no} `}} 
                                    <strong>Note:</strong>{{`  ${detalles.special_requirements}`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Otr'">
                                    <strong>Moto:</strong>{{`  ${detalles.arrival_flight_no} `}}
                                  </div>
                                  <div v-else>
                                    <span class="mr-2" v-if="detalles.service_type == 'IN'"><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    <strong>Flight:</strong>{{` ${ detalles.arrival_airline + ' - ' + detalles.arrival_flight_no}`}}
                                  </div>
                                </div>
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Flight</strong>{{` ${ detalles.departure_airline + ' - ' + detalles.departure_flight_no }`}}</span>
                                  
                              </v-list-item-subtitle>
                              <v-card-actions class="d-flex justify-space-between pl-0">
                                <div>
                                  <span class="text--primary ma-0 text--subtittle-1">Acciones</span>
                                </div>
                                <div>
                                  <v-icon color="warning" small class="mr-2" @click="editRowInvoiceDetail(detalles, item)">
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon color="red" class="mr-2" @click="openDelete(detalles, 'invoiceDetail', item)">
                                    mdi-delete
                                  </v-icon>
                                  <v-tooltip top v-if="detalles.calendar_operation">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn 
                                      dark
                                      icon
                                      :href="detalles.calendar_operationId.htmlLink"
                                      target="_blank"
                                      >
                                        <v-icon                                       
                                          color="green" 
                                          v-bind="attrs"
                                          v-on="on" 
                                        >
                                          mdi-calendar-multiple-check
                                        </v-icon>                                  
                                      </v-btn>
                                    </template>
                                    <span>Evento con calendario</span>
                                  </v-tooltip>
                                  <v-tooltip top v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) != 'Otr'">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn 
                                        dark
                                        icon                                    
                                        >
                                        <v-icon 
                                          color="red"
                                          v-bind="attrs"
                                          v-on="on" 
                                          @click="createEventCalendar(detalles, item, 'create')">
                                          mdi-calendar-question
                                        </v-icon> 
                                      </v-btn>               
                                    </template>
                                    <span>**Evento sin Calendario**</span>
                                  </v-tooltip>
                                  <v-icon 
                                    v-if="detalles.calendar_operation"
                                    color="blue" 
                                    class="mr-2" 
                                    @click="createEventCalendar(detalles, item, 'update')">
                                    mdi-calendar-refresh
                                  </v-icon>
                                  <v-icon 
                                    v-if="detalles.calendar_operation"
                                    color="red" 
                                    class="mr-2" 
                                    @click="createEventCalendar(detalles, item, 'delete')">
                                    mdi-calendar-remove
                                  </v-icon>
                                </div>


                              </v-card-actions>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </td>
                </template>
        </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="closeDialogInfoReservas"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Invoice Detail Form -->
    <v-row justify="center" >
      
        <v-dialog  v-model="dialogInvoiceDetail" persistent max-width="700px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" max-width="700px">
            <v-card-title class="py-1">
              <span class="text-h6">{{ btnEdithVisible ? `Edicion de la linea detalle No. ${createInvoiceDetailForm.id}` : `Crear Detalle Invoice` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-form ref="createInvoiceDetailForm" v-model="validInvoiceDetail" >
              <v-card-text class="mt-2 pb-0">
                
                <v-container class="pb-0 pt-1">
                  <!-- Linea Producto IN-->
                  <v-row dense justify="start">
                    <v-col cols="12" sm="6" md="6">                   
                      <v-autocomplete
                        v-if="!btnEdithVisible"
                        ref="edit_product"
                        class="text-caption"
                        v-model="createInvoiceDetailForm.product_invoice_detail"
                        item-value="id"
                        dense
                        :items="product"
                        :item-text="itemTextProduct"
                        label="Producto"
                        placeholder="Escojer un producto"
                        required
                        :rules="[createFormRules.required]"
                        clearable
                        @change="lookupTourOption"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-else
                        ref="edit_product"
                        class="text-caption"
                        v-model="createInvoiceDetailForm.product_invoice_detail"
                        item-value="id"
                        dense
                        disabled
                        :items="productEdit"
                        :item-text="itemTextProductEdit"
                        label="Producto"
                        placeholder="Escojer un producto"
                        required
                        :rules="[createFormRules.required]"
                        clearable
                        @change="lookupTourOption"
                      ></v-autocomplete>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6" v-if="optionProductInOut">
                      <div class="d-flex justify-center">
                        <span>Es In y Out</span>
                      </div>
                    </v-col>
                    <v-col cols="12" md=6 lg="6" v-if="visibleSwith" class="d-flex justify-end">
                      <v-radio-group
                          :disabled="btnEdithVisible"
                          v-model="swithServices"
                          row
                          @change="verificarSwitth"
                        >
                          <v-radio
                            label="In"
                            value="IN"
                          ></v-radio>
                          <v-radio
                            label="Out"
                            value="OUT"
                          ></v-radio>
                        </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-card-subtitle v-if="detailIn" class="pl-0 py-1">
                    <div v-if="otrosCargos">
                      <span><strong>Favor indicar los datos del servicio adicional</strong></span>
                    </div>
                    <div v-else>
                      <span><strong>Favor indicar los datos del servicio IN</strong></span>
                    </div>
                  </v-card-subtitle>
                  <!-- Linea Nombre de Pasajeros IN-->
                  <v-row dense  v-if="detailIn && !otrosCargos" justify="space-around">
                    <v-col cols="12" md="4">
                      <v-switch
                        v-model="switchInNombrePax"
                        label="Activar Nombres de Pax"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-textarea
                        rows="2"
                        :disabled="!switchInNombrePax" 
                        v-model="createInvoiceDetailForm.arrival_traveler_names"
                        label="Nombres de Pax"
                        auto-grow
                        >

                      </v-textarea>
                    </v-col>
                  </v-row>
                  <!-- Linea Fechas y lugares IN-->
                  <v-row dense align="center" v-if="detailIn " justify="space-between">
                    <v-col cols="12" md="3" class="mr-2">
                      <v-menu
                        v-model="menuTravelDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        required
                      
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="py-1"
                            v-model="createInvoiceDetailForm.arrival_date"
                            :label="atv && !scooter ? `Fecha Atv`: tour ? `Fecha Tour` : atv && scooter ? `Fecha Entrega` : otrosCargos ? `Fecha extra` : `Fecha del viaje`"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createInvoiceDetailForm.arrival_date"
                          @input="menuTravelDate = false"
                          :rules="[createFormRules.required]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="2" class="mr-2" v-if="!otrosCargos">
                      <v-menu
                        ref="menuArrival"
                        v-model="arrivalTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="createInvoiceDetailForm.arrival_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="createInvoiceDetailForm.arrival_time"
                            label="Hora"
                            class="text-caption py-1"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="arrivalTime"
                          v-model="createInvoiceDetailForm.arrival_time"
                          format="24hr"
                          full-width
                          @click:minute="$refs.menuArrival.save(createInvoiceDetailForm.arrival_time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-0" v-if="!otrosCargos">                      
                      <v-combobox
                        :key="arrival_pickUp_locationKey" 
                        :disabled="btnEdithVisible ? false : disablePickUpLocation"
                        dense
                        label="Pick up Location"
                        v-model="createInvoiceDetailForm.arrival_pickUp_location"
                        :rules="[createFormRules.required]"
                        :items="placeOptions"
                        clearable
                        hide-selected
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-0" v-if="!otrosCargos">
                      <v-combobox                       
                        dense
                        label="Drop off Location"
                        v-model="createInvoiceDetailForm.arrival_dropOff_location"
                        :rules="[createFormRules.required]"
                        :items="placeOptions"
                        clearable
                        hide-selected
                        >
                      </v-combobox>
                    </v-col>
                  </v-row >
                  <!-- Linea de Hora de Recigida -->
                  <!-- <v-row dense align="center" v-if="!otrosCargos">
                    <v-col cols="12" md="4"> 

                    </v-col>
                  </v-row> -->
                  <!-- Linea Vuelo IN-->
                  <v-row dense align="center" v-if="detailIn" :justify="otrosCargos ?'start' : 'space-around'">
                    <v-col cols="12" md="2" v-if="!otrosCargos">
                      <v-text-field                        
                        type="number"
                        min="0"
                        dense
                        label="No.Pax"
                        v-model="createInvoiceDetailForm.arrival_pax"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" v-if="!otrosCargos">
                      <v-text-field                        
                        type="number"
                        min="0"
                        dense
                        label="No.Kids"
                        v-model="createInvoiceDetailForm.arrival_pax_kids"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!otrosCargos">
                      <v-combobox
                        v-if="!atv && !tour"                        
                        dense
                        label="Aerolinea"
                        v-model="createInvoiceDetailForm.arrival_airline"
                        :rules="[createFormRules.required]"
                        :items="airlinesItems"
                        clearable
                        hide-selected
                        required>
                      </v-combobox>
                      <v-menu
                        v-if="scooter"
                        v-model="menuDepartureDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        required
                      
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="py-1"
                            v-model="createInvoiceDetailForm.departure_date_deliver"
                            label="Fecha de Recogida"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createInvoiceDetailForm.departure_date_deliver"
                          @input="menuDepartureDate = false"
                          :rules="[createFormRules.required]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-if="!atv && !tour && !otrosCargos"                        
                        dense
                        label="No. Vuelo"
                        v-model="createInvoiceDetailForm.arrival_flight_no"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                      <v-autocomplete
                        v-if="scooter || otrosCargos"
                        v-model="createInvoiceDetailForm.arrival_flight_no"
                        item-value="id"
                        dense
                        multiple
                        :return-object="false"
                        :items="motosOption"
                        item-text="value"                        
                        label="Seleccione Moto"
                        placeholder="Moto a alquilar"
                        required
                        :rules="[createFormRules.required]"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" class="mr-2" v-if="detailIn && atv">
                      <v-btn
                        color="primary"
                        elevation="6"
                        rounded
                        small
                        @click="chooseAtvType(createInvoiceDetailForm)"
                      >Tipo Atv</v-btn>
                    </v-col>
                  </v-row >

                  <!-- EQUIPOS ESCOGIDOS DE ATV -->
                  <v-row dense align="center"  justify="center" class="pb-2" v-if="detailIn && atv">
                    <v-col cols="8">
                      <v-card class="py-2" elevation="0" outlined>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">
                                  Cant
                                </th>
                                <th class="text-center">
                                  Tipo
                                </th>
                                <th class="text-center">
                                  Precio
                                </th>
                                <th class="text-center">
                                  Total
                                </th>
                                <th class="text-center">
                                  Acciones
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in atvLines"
                                :key="item.index"
                              >
                                <td class="text-center" style="height: 10px; !important">{{ item.cant }}</td>
                                <td class="text-center" style="height: 10px; !important">{{ item.tipo }}</td>
                                <td class="text-end" style="height: 10px; !important">{{ convertToMoney(parseFloat(item.precio ? item.precio: 0)) }}</td>
                                <td class="text-end" style="height: 10px; !important">{{ convertToMoney(parseFloat(item.precio)*parseFloat(item.cant)) }}</td>
                                <td class="text-center" style="height: 10px; !important">
                                  <v-btn
                                    style="height: 10px; !important"
                                    class="pa-0 ma-0"
                                    fab
                                    dark
                                    samll
                                    icon
                                    color="red"
                                    @click="deleteLineAtvDetail(item)"
                                  >
                                    <v-icon dark>mdi-minus</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                      <div class="py-1">
                        <span class="pr-1">Total Precio en detalles</span>
                        <span class="pl-1">{{`${convertToMoney(sumatoriaPrecioDetalles)}`}}</span>
                      </div>
                      <span v-if="checkTotalvsSumaDetails">El total del detalle es diferente al original</span>
                    </v-col>
                  </v-row>

                  <!-- Linea Tarifa IN-->
                  <v-row dense align="center" v-if="detailIn" justify="space-between">
                    
                    <v-col cols="12" md="1" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"                        
                        dense
                        label="Qty"
                        v-model.number="createInvoiceDetailForm.arrival_quantity"
                        :rules="[createFormRules.required]"
                        @input="calculoValorQty(createInvoiceDetailForm.arrival_quantity,'IN')"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"
                        step='10' value='0.00' lang="en"                        
                        dense
                        label="Tarifa"
                        v-model.number="createInvoiceDetailForm.arrival_rate"
                        :rules="[createFormRules.required]"
                        @input="calculoValor(createInvoiceDetailForm.arrival_rate,'IN')"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        :key="arrivalAmountKey"
                        type="number"
                        min="0"                        
                        dense
                        disabled
                        label="Valor"
                        v-model.number="createInvoiceDetailForm.arrival_amount"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"    
                        dense
                        disabled
                        label="Impuesto"
                        v-model="createInvoiceDetailForm.arrival_tax"
                        required>
                      </v-text-field>
                    </v-col>
                    
                  </v-row>
                  <!-- Linea tel telephone y especial requirement IN-->
                  <v-row dense  align="center" v-if="detailIn && !otrosCargos" justify="space-around" >
                    <v-col cols="12" md="5" class="mr-2">
                      <vue-tel-input-vuetify
                        label="Telefono contacto" 
                        placeholder="Ingrea indicativo y numero"
                        v-model="createInvoiceDetailForm.arrival_phone"
                        :rules="[createFormRules.required]"                        >

                      </vue-tel-input-vuetify>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-textarea
                        rows="2" 
                        v-model="createInvoiceDetailForm.arrival_special_requirements"
                        label="Requerimiento adicional"
                        auto-grow
                        >

                      </v-textarea>
                    </v-col>
                  </v-row>
                  
                </v-container>
                <v-divider v-if="detailOut && detailIn"></v-divider>
                <v-card-subtitle v-if="detailOut" class="pl-3">
                  <span><strong>Favor indicar los datos del servicio OUT</strong></span>
                </v-card-subtitle>
                <v-container v-if="detailOut" class="pt-0 pb-1">
                   <!-- Linea Nombre de pax OUT-->
                  <v-row dense  v-if="detailOut" justify="space-around">
                    <v-col cols="4" md="4">
                      <v-switch
                        v-model="switchOutNombrePax"
                       label="Activar Nombres de Pax"
                      ></v-switch>
                    </v-col>
                    <v-col cols="8" md="8">
                      <v-textarea
                        rows="2"
                        :disabled="!switchOutNombrePax" 
                        v-model="createInvoiceDetailForm.departure_traveler_names"
                        label="Nombres de Pax"
                        auto-grow
                        >

                      </v-textarea>
                    </v-col>
                  </v-row>
                   <!-- Linea Fechas y lugares OUT-->
                  <v-row dense align="center" justify="space-between">
                   <v-col cols="12" md="3" class="mr-2">
                      <v-menu
                        v-model="menuDepartureDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        required
                      
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="py-1"
                            v-model="createInvoiceDetailForm.departure_date"
                            label="Fecha Viaje"
                            prepend-icon="mdi-calendar"
                            readonly
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createInvoiceDetailForm.departure_date"
                          @input="menuDepartureDate = false"
                          :rules="[createFormRules.required]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="2" class="mr-2">
                      <v-menu
                        ref="menuDeparture"
                        v-model="departureTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="createInvoiceDetailForm.departure_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="createInvoiceDetailForm.departure_time"
                            label="Hora"
                            class="text-caption py-1"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="departureTime"
                          v-model="createInvoiceDetailForm.departure_time"
                          format="24hr"
                          :rules="[createFormRules.required]"
                          full-width
                          @click:minute="$refs.menuDeparture.save(createInvoiceDetailForm.departure_time)"
                          @input="defaultHoraPickupDeparture(createInvoiceDetailForm.departure_time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-0">
                      <v-combobox                       
                        dense
                        label="Pick up Location"
                        v-model="createInvoiceDetailForm.departure_pickUp_location"
                        :rules="[createFormRules.required]"
                        :items="placeOptions"
                        clearable
                        hide-selected
                        required>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-0">
                      <v-combobox
                        :key="departure_dropOff_locationKey"
                        :disabled="btnEdithVisible ? false : disableDropOffLocation"                        
                        dense
                        label="Drop off Location"
                        v-model="createInvoiceDetailForm.departure_dropOff_location"
                        :rules="[createFormRules.required]"
                        :items="placeOptions"
                        clearable
                        hide-selected
                        required>
                      </v-combobox>
                    </v-col>
                  </v-row >
                  <!-- Linea Vuelo OUT-->
                  <v-row dense align="center" justify="space-around">
                    <v-col cols="12" md="2" >
                    <v-text-field                        
                        type="number"
                        min="0"
                        dense
                        label="No.Pax"
                        v-model="createInvoiceDetailForm.departure_pax"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" >
                      <v-text-field                        
                        type="number"
                        min="0"
                        dense
                        label="No.Kids"
                        v-model="createInvoiceDetailForm.departure_pax_kids"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" >
                      <v-combobox                        
                        dense
                        label="Aerolinea"
                        v-model="createInvoiceDetailForm.departure_airline"
                        :rules="[createFormRules.required]"
                        :items="airlinesItems"
                        clearable
                        hide-selected
                        required>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" md="4" >
                      <v-text-field                        
                        dense
                        label="No. Vuelo"
                        v-model="createInvoiceDetailForm.departure_flight_no"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                    </v-col>
                  </v-row >
                  <!-- Linea de Hora de Recogida -->
                  <v-row dense align="center" >
                    <v-col cols="12" md="4"> 
                      <v-menu
                        ref="menuDeparturePickupTime"
                        v-model="departureTimePickup"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="createInvoiceDetailForm.pick_up_departure_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="createInvoiceDetailForm.pick_up_departure_time"
                            label="Hora de recogida"
                            class="text-caption py-1"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            :rules="[createFormRules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="departureTimePickup"
                          v-model="createInvoiceDetailForm.pick_up_departure_time"
                          format="24hr"
                          :rules="[createFormRules.required]"
                          full-width
                          @click:minute="$refs.menuDeparturePickupTime.save(createInvoiceDetailForm.pick_up_departure_time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <!-- Linea Tarifa OUT-->
                  <v-row dense align="center" justify="space-between">
                    
                    <v-col cols="12" md="1" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"                        
                        dense
                        label="Qty"
                        v-model.number="createInvoiceDetailForm.departure_quantity"
                        :rules="[createFormRules.required]"
                        @input="calculoValorQty(createInvoiceDetailForm.departure_quantity,'OUT')"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"                        
                        dense
                        label="Tarifa"
                        v-model.number="createInvoiceDetailForm.departure_rate"
                        :rules="[createFormRules.required]"
                        @input="calculoValor(createInvoiceDetailForm.departure_rate,'OUT')"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        :key="departureAmountKey"
                        type="number"
                        min="0"
                        step='10' value='0.00' lang="en"                        
                        dense
                        disabled
                        label="Valor"
                        v-model.number="createInvoiceDetailForm.departure_amount"
                        :rules="[createFormRules.required]"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-2">
                      <v-text-field
                        type="number"
                        min="0"                        
                        dense
                        disabled
                        label="Impuesto"
                        v-model="createInvoiceDetailForm.departure_tax"
                        required>
                      </v-text-field>
                    </v-col>
                    
                  </v-row>
                   <!-- Linea tel telephone y especial requirement OUT-->
                  <v-row dense  v-if="detailOut" justify="space-around">
                    <v-col cols="12" md="5" class="mr-2">
                      <vue-tel-input-vuetify
                        label="Telefono contacto" 
                        placeholder="Ingrea indicativo y numero"
                        v-model="createInvoiceDetailForm.departure_phone"
                        :rules="[createFormRules.required]"                        >

                      </vue-tel-input-vuetify>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-textarea 
                        v-model="createInvoiceDetailForm.departure_special_requirements"
                        label="Requerimiento adicional"
                        auto-grow
                        rows="2"
                        >
                        
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="pb-0">
                  <v-row dense align="center">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-if="btnEdithVisible"
                        type="number"
                        min="0"                        
                        dense
                        label="Enlace Calendario"
                        v-model="createInvoiceDetailForm.calendar_operation"
                        required>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
           
              <v-card-actions class="py-1">  
                <v-footer 
                  class="d-flex justify-end" 
                  color="white"
                  >
                    
                  <v-btn 
                    :disable="isLoading"
                     v-if="!btnEdithVisible"
                    color="blue darken-1" text @click="closeForm('invoiceDetail')">
                    Cancelar
                  </v-btn>
                  <v-btn 
                    :disable="isLoading"
                    v-if="btnEdithVisible"
                    color="blue darken-1" text @click="closeForm('invoiceDetail', 'PUT')">
                    Cancelar Edicion
                  </v-btn>
                  <v-btn
                    :loading="isLoading"
                    v-if="!btnEdithVisible && saveContinue"
                    color="blue darken-1"
                    text
                    @click="preSummit"
                  >
                    Guardar y seguir
                  </v-btn>
                  <!-- <v-btn
                    :loading="isLoading"
                    v-if="!btnEdithVisible && createInvoiceDetailFormRecords.length"
                    color="blue darken-1"
                    text
                    @click="summitForm('POST', 'invoiceDetail')"
                  >
                    Guardar y cerrar
                  </v-btn> -->
                  <v-btn
                    :loading="isLoading"
                    v-if="!btnEdithVisible && createInvoiceDetailFormRecords.length"
                    color="blue darken-1"
                    text
                    @click="summitForm('POST', 'invoiceDetail', 'cerrar')"
                  >
                    Guardar y cerrar
                  </v-btn>
                  <v-btn
                    :loading="isLoading"
                    v-if="btnEdithVisible"
                    color="blue darken-1"
                    text
                    @click="saveEdition"
                  >
                    Guardar Edicion
                  </v-btn>
                  
                  
                </v-footer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      
    </v-row>

     <!-- DIALOG DELETE INVOICE DETAIL -->
    <v-dialog v-model="dialogDeleteInvoiceDetail" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este registro?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete('invoiceDetail')">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm('invoiceDetail')">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Creacion de atv details -->
    <v-row justify="center" >
      <v-form ref="createFormAtvDetail" v-model="validAtvDetail" lazy-validation >
        <v-dialog  v-model="dialogAtvDeteail" persistent max-width="500px">
          <v-card :loading="isLoadingAtvDetail" :disabled="isLoadingAtvDetail" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ `Detalles Atv` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md=3>
                    <v-text-field
                      dense
                      label="Cantidad"
                      v-model="createFormAtvDetail.cant"
                      :rules="[createFormRules.required]"
                      placeholder="Cantidad"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=3>
                    <v-autocomplete
                      clearable
                      :items="tiposATV"
                      dense
                      label="tipo"
                      v-model="createFormAtvDetail.tipo"
                      :rules="[createFormRules.required]"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md=3>
                    <v-text-field
                      type="number"
                      min="0"
                      step='10' value='0.00' lang="en"
                      dense
                      label="Precio"
                      v-model="createFormAtvDetail.precio"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=3>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="addLineAtvDetail(createFormAtvDetail)"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>

                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeDialogAtvDetail">
                Cerrar
              </v-btn>
              <!-- <v-btn
                :loading="isLoading"
                color="blue darken-1"
                text
                @click=""
              >
                Guardar
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

      <!-- Creacion simple de customer desde este modulo -->
    <v-row justify="center" >
      <v-form ref="createCustomerForm" v-model="validCustomer" lazy-validation >
        <v-dialog  v-model="dialogCustomer" persistent max-width="500px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ `Creacion de cliente factura` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md=3>
                    <v-text-field
                      dense
                      label="Nombres"
                      v-model="createCustomerForm.first_name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md=3>
                    <v-text-field
                      dense
                      label="Apellidos"
                      v-model="createCustomerForm.last_name"
                      :rules="[createFormRules.required]"
                      @keyup.enter="summitForm('POST', 'customer')"
                    >
                    </v-text-field>
                  </v-col>
                  
                </v-row>
                     
               
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm('customer', btnEdithVisible ? 'PUT': '')">
                Cerrar
              </v-btn>
              
              <v-btn
                :loading="isLoading"
                color="blue darken-1"
                text
                @click="summitForm('POST', 'customer')"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- form para envio de email -->
    <v-row>
      <v-form>
        <div class="text-center">
          <v-dialog
            v-model="sendEmailDialog"
            width="500"
          >
            

            <v-card :loading="isLoading" :disabled="isLoading">
              <v-card-title class="text-h5 grey lighten-2">
                Envio de Email
              </v-card-title>

              <v-card-text>
                <div>
                  <p>Si el cliente en esta factura tiene email en el sistema aparecera en el campo, 
                    si no, puede colocar manual, esta accion guarda el email colocado aqui, esto es solo para enviar.
                    Si el cliente es repetitivo debe actualizar el mismo en el modulo de operaciones/venta/customers.
                  </p>
                </div>
                <v-divider></v-divider>
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- email opcion to -->
                    <v-combobox
                      v-model="model"
                      :filter="filter"
                      :hide-no-data="!search"
                      :items="items"
                      :search-input.sync="search"
                      hide-selected
                      label="Emails To"
                      small-chips
                      multiple
                      solo
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">Create</span>
                          <v-chip
                            :color="`${colors[nonce - 1]} lighten-3`"
                            label
                            small
                          >
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.text }}
                          </span>
                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.text"
                          autofocus
                          flat
                          background-color="transparent"
                          hide-details
                          solo
                          @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <v-chip
                          v-else
                          :color="`${item.color} lighten-3`"
                          dark
                          label
                          small
                        >
                          {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                          <v-btn
                            icon
                            @click.stop.prevent="edit(index, item)"
                          >
                            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-combobox>
                    <!-- email option cc -->
                    <v-combobox
                      placeholder="CC"
                      v-model="model2"
                      :filter="filter"
                      :hide-no-data="!search2"
                      :items="items2"
                      :search-input.sync="search2"
                      hide-selected
                      label="Emails CC"
                      small-chips
                      multiple
                      solo
                      clearable
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">Create</span>
                          <v-chip
                            :color="`${colors2[nonce2 - 1]} lighten-3`"
                            label
                            small
                          >
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.text }}
                          </span>
                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.text"
                          autofocus
                          flat
                          background-color="transparent"
                          hide-details
                          solo
                          @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <v-chip
                          v-else
                          :color="`${item.color} lighten-3`"
                          dark
                          label
                          small
                        >
                          {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                          <v-btn
                            icon
                            @click.stop.prevent="edit(index, item)"
                          >
                            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                {{model}} 
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="envioMailPdf"
                >
                  Enviar Factura
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-form>
    </v-row>

    <!-- Cuadro de busqueda -->
    <v-row dense align="center" justify="center">
      <v-form ref="searchForm" v-model="validSearch" lazy-validation >
        <v-dialog  v-model="dialogSearch" max-width="500px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              Busqueda avanzada
            </v-card-title>
            <v-card-subtitle>
              Si no llena alguno de los cuadros abajo señalados, traera todos los registros de la base de datos.
            </v-card-subtitle>
            <v-card-text class="pb-1">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <div v-for="campo in headers.busqueda" :key="campo.value">
                      <v-text-field 
                        class="campos-search"
                        dense
                        outlined
                        v-model="searchForm[campo.value]"
                        :label="campo.text"
                        @change="campo.prefix ? addPrefix(campo.value, campo.textPrefix) : false"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-container fluid class="pt-0">
                <v-row dense>
                  <v-col cols="12" md="12" class="d-flex justify-end">
                    <v-btn
                      small
                      rounded
                      color="primary"
                      @click="advancedSearch(searchForm)"
                    >Buscar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>
    
  </div>
</template>

<script>
import {axiosN506, axiosB} from '../api/axiosBd'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import airlines from '../api/airlines'
import axios from 'axios'
export default {
  components: {
    VueTelInputVuetify,
  },
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //data de email send
      activator: null,
      attach: null,
      colors: ['green'],
      editing: null,
      editingIndex: -1,
      items: [
        { header: 'Escriba el correo o los correos a enviar' },
      ],
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0,
      /////
      activator2: null,
      attach2: null,
      colors2: ['green'],
      editing2: null,
      editingIndex2: -1,
      items2: [
        { header: 'Escriba el correo o los correos a enviar con copia' },
      ],
      nonce2: 1,
      menu2: false,
      model2: [],
      x2: 0,
      search2: null,
      y2: 0,
      /////
      reservationDateKey:0,
      reservationDateKey1:0,
      reservationDateKey2:0,
      reservationDueDateKey:0,
      reservationBillStateKey:0,
      reservationBill_toKey:0,
      arrivalAmountKey: 0,
      departureAmountKey: 0,
      arrival_pickUp_locationKey: 0,
      departure_dropOff_locationKey: 0,
      switchOutNombrePax: false,
      switchInNombrePax: false,
      time: null,
      menu2: false,
      arrivalTime: false,
      departureTime: false,
      departureTimePickup: false,
      disablePickUpLocation: false,
      disableDropOffLocation: false,
      //loadings
      isLoading: false,
      saveContinue: false,
      loadingInvoiceTable: false,
      loadingTableInfoReservas:false,
      loadingDetailTable: false,
      //dialog delete
      dialogDeleteProduct: false,
      dialogDeleteInvoiceDetail: false,
      //search
      invoiceSearch: '',
      //records
      invoice_records: [],
      folio: [],
      customers: [],
      //form invoice
      dialogCustomer: false,
      dialogCustomer: false,
      validCustomer: false,
      menuStarDate: false,
      
      //detalle de factura
      menuTravelDate: false,
      menuDepartureDate: false,

      ///
      menuFinalDate: false,
      btnEdithVisible: false,
      valid: true,
      validInvoiceDetail:true,
      dialogInvoice: false,
      dialogInvoiceDetail: false,
      dialogInfoInvoice: false,
      detailIn: false,
      detailOut: false,
      swithServices: false,
      visibleSwith: false,
      atv: false,
      tour: false,
      otrosCargos: false,
      scooter: false,
      typeReservation: false,

      //froms
      createInvoiceForm: {
        bill_state : "PENDIENTE"
      },
      createInvoiceDetailForm: {},
      createInvoiceDetailFormRecords: [],
      createInvoiceDetailForm: {},
      createInvoiceDetailFormRecords: [],
      createCustomerForm: {},
      createDetailIn:{},
      createDetailOut:{},
      selectedReservation: {},

      
      recentInvoiceDetail: [],
      recentInvoice: [],
      product: [],
      productEdit: [],
      reservations: [],
      allReservation: [],
      selectedProduct: null,
      headersInfoReserva: [
        {
          text: 'No. Factura',
          align: 'center',
          value: 'invoice_folioId.id',
          busqueda: true,
        },
        {
          text: 'Reserva',
          align: 'center',
          value: 'reservationId.booking_reference',
          busqueda: true,
        },
        {
          text: 'Fecha',
          align: 'center',
          value: 'invoice_folioId.date',
          busqueda: true,
        },
        {
          text: 'Estado Factura',
          align: 'center',
          value: 'invoice_folioId.bill_state',
          busqueda: false,
        },
        {
          text: 'Balance',
          align: 'rigth',
          value: 'invoice_folioId.balance_due',
          busqueda: false,
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions',
          busqueda: false,
        },
        // { text: "", sortable: false, width: 100 },
      ],
      billStateOptions: [
        {id: 'PENDIENTE', value: 'PENDIENTE'},
        {id: 'PAGADA', value: 'PAGADA'},
      ],
      tourOption: [
        {id: 'IN', value: 'IN'},
        {id: 'OUT', value: 'OUT'},
      ],
      motosOption: [
        {id: 'Moto #1', value: 'Moto #1'},
        {id: 'Moto #2', value: 'Moto #2'},
        {id: 'Moto #3', value: 'Moto #3'},
        {id: 'Moto #4', value: 'Moto #4'},
        {id: 'Moto #5', value: 'Moto #5'},
        {id: 'Moto #6', value: 'Moto #6'},
        {id: 'Moto #7', value: 'Moto #7'},
        {id: 'Moto #8', value: 'Moto #8'},
        {id: 'Moto #9', value: 'Moto #9'},
        {id: 'Moto #10', value: 'Moto #10'},
        {id: 'Moto #11', value: 'Moto #11'},
        {id: 'Moto #12', value: 'Moto #12'},
      ],
      placeOptions: [
        'SJO Airport',
        'Rio Santo 22, Tamarindo',
        'Casa Coco de Langosta Tamarindo',
        'Casa 54 Palm Beach Estates Playa Grande, Guanacaste',
        'Tamarindo Bay Boutique Hotel',
        'Hotel, Occidental Tamarindo',
        'Sunrise Condos, Tamarindo, Guanacaste 50309',
        'Brisas 41 Pinilla',
        'VC-16 Villa de las Olas Villas Catalinas Playa Potrero 50304',
        'Wyndham Hotel / Tamarindo',
        'Hotel Mar Rey, Tamarindo',
        'Hotel Tamarindo Diria',
        'TAMARINDO - 506 ATV Tours',
        'El Manglar Playa Grande',
        'Liberia Airport',
        'Jardin del Eden Boutique Hotel',
        '10 north tamarindo hotel',
        'Occidental Tamarindo Hotel',
        'Occidental Papagayo',
        'The Retreat, Atenas',
        'Capitan Suizo Beachfront',
        'The Springs Resort & Spa',
        'Wyndham Sjo Herradura',
        'The Springs',
        'El Chiringuito',
        'Ten North Tamarindo Beach Hotel',
        'Casa Patricia',
        'Crystal Sands #502',
        'Casa Cocoa',
        'Pangas Beach Club',
        'Hotel Cala Luna',
        'Hotel El Manglar',
        'Casa Aura',
        'Ibis 1 (Langosta)',
        'Planet Hollywood Resort',
      '  Cala Luna hab 105',
        'Casa Benedick',
        'BOUGAINVILLEA 8315 (RESERVA)',
        'Casa Paquita 74rx64R Playa Langosta',
        'JW Marriott',
        'Hotel Capitan Suizo Tamarindo',
        'Casa Chano LDV # 10',
        'W Costa Rica',
        'Villa Nacara',
        'Casa Cocoa Tamarindo',
        'Casa Secreto (Colibri 55D)',
        'Rio Celeste Tour from Riu Hotels',
        '506 Base',
        'Hilton Garden Inn'
      ],
      tiposATV:[
        'DirtBike',
        'Sencilla',
        'Doble',
        '2-Buggy',
        '4-Buggy',
      ],
      createFormAtvDetail: {},
      atvLines: [],
      isLoadingAtvDetail: false,
      dialogAtvDeteail: false,
      validAtvDetail: false,

      createLocationForm: {},
      location: [],
      isLoadingLocation: false,
      dialogLocation: false,
      validLocation: false,
      btnEditLocation: false,
      selectedLocationField: null,

      optionProductInOut: false,
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        counter: value => value ? value.length <= 25 || 'Max 25 Caracteres': '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null,
      sendEmailDialog: false,
      formEmail: {},
      dataPdf:{},
      selectedCustomer:{},
      timer: null,
      currentPage: 1,
      totalPages: 0,

      //serch
      validSearch: false,
      dialogSearch: false,
      searchForm: {},

    
    }
  },
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
    },
    model2 (val, prev) {
      if (val.length === prev.length) return

      this.model2 = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors2[this.nonce2 - 1],
          }

          this.items2.push(v)

          this.nonce2++
        }

        return v
      })
    },
  },
  computed: {
    airlinesItems(){
      let data =[]
      for (let item of airlines.airlines){
        data.push(item.name)
      }
      return data
    },
    sumatoriaPrecioDetalles(){
      const suma = this.atvLines.map(item => parseFloat(item.precio)*parseFloat(item.cant)).reduce((a, b) => a + b, 0)
      return suma
    },

    checkTotalvsSumaDetails(){
      if (!this.btnEdithVisible){
        this.arrivalRateKey +=1
        this.createInvoiceDetailForm.arrival_rate = this.sumatoriaPrecioDetalles
        return false
      } else {
        if (this.createInvoiceDetailForm.arrival_rate == this.sumatoriaPrecioDetalles || this.sumatoriaPrecioDetalles == 0){
          return false
        } else {
          return true
        }
      }
    },
   
    openReservations(){
      let reservas = []
      this.folio.filter((item)=> {
        if (item.reservationId  && !item.invoice_folio){
          if (item.reservationId.reservation_status == 1 || item.reservationId.reservation_status == 2){
            reservas.push(item)
          }
        }
      })
      return reservas
    },
    invoiceDetail(){
      let recent = [] 
      this.folio.filter((f)=> {
        this.loadingTableInfoReservas = true
        if (this.recentInvoice && f.invoice_folioId){
          if (f.invoice_folioId.id == this.recentInvoice.id) {  
            recent.push(f)
          }
        }
      })
      this.loadingTableInfoReservas = false
      return recent
    },    
  },
  methods: {
    addPrefix(campo, prefix){
      const value = this.searchForm[campo]
      if (!value.includes('BR-')) this.searchForm[campo] = `${prefix}${value}`
    },
    resetData(){
      const page = this.currentPage
      const size = 20
      this.getData(this.boards.folio, {page, size})
    },
    gotoPage(item){
      this.currentPage = item
      const page = item
      const size = 20
      this.getData(this.boards.folio, {page, size})
    },
    openSearch(){
      this.dialogSearch = true
    },
    advancedSearch(form){
      console.log(form)
      const page = this.currentPage
      const size = 20
      this.getData(this.boards.folio, {page, size},form)
    },

    validateEmail(emailField){
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test(emailField) == false){
          return false
      }
      return true
    },
    /////
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    /////
    dialogSendEmail(item){
      this.sendEmailDialog = true
      this.selectedCustomer = {}
      this.model = []
      this.model2 = []
      this.selectedCustomer = this.customers.find((customer)=> customer.id == item.invoice_folioId.bill_to)
      this.dataPdf = this.invoicePdf(item,false)
      if (!this.selectedCustomer.emailId){
        console.log('no tiene')
      } else {
        let obj = {
          text: this.selectedCustomer.emailId.email,
          color: 'blue',
        }
        this.model.push(obj)
      }
    },
    async envioMailPdf(){
      this.isLoading = true
      if (this.model.length && this.validateEmail(this.model[0].text) || this.model2.length && this.validateEmail(this.model2[0].text)) {
        let obj = {
          individual: true,
          para: this.model.length && this.validateEmail(this.model[0].text) ? this.model[0].text : null,
          cc: this.model2.length && this.validateEmail(this.model2[0].text) ? this.model2[0].text : null,
          customer: this.selectedCustomer
        }
        let body = { obj, ...this.dataPdf}
  
        await this.$store.dispatch('verifyTokenPy')
         
        axiosB(`extractor/api/506transfers/envioFacturaEmail`, {
          method: "POST",
          headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}` },
          data: body
        })
        .then((result) => {
          this.sendEmailDialog = false
          this.selectedCustomer = {}
          this.model = []
          this.model2 = []
          this.isLoading = false
          this.$notify({
            title: "Email Enviado",
            message: "El mensaje ha sido enviado al destinatario.",
            type: "success",
            duration: 6500,
          })
          
        }).catch((err) => {
          console.log(err.response)
          this.isLoading = false
          this.$notify({
            title: "Error en el envio del mail",
            message: "Si este error persiste, contacte al servicio de soporte.",
            type: "success",
            duration: 6500,
          })
        })
      } else {
        alert('Los campos Email To y Email CC, uno de los dos campos no puede estar vacio o el email esta mal escrito')
         this.isLoading = false
      }
       
    },
    
    async createEventCalendar(detalles, item, action){
      if (!detalles.calendar_operationId || action == 'update' || action == 'delete'){
        this.loadingTableInfoReservas = true
        let createFormEvent = {}
        let url = null
        if (detalles.service_type == 'IN'){
          if (detalles.product_invoice_detailId && detalles.product_invoice_detailId.short_name.slice(0,3) == 'R-T' || detalles.product_invoice_detailId.short_name.slice(0,3) == 'One'){
            let nombresCalendario = ''
            if (detalles.booking_reference.slice(0,7) == 'BR-506T'){
              nombresCalendario = `${detalles.traveler_names ? detalles.traveler_names: item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar`
            } else {
              nombresCalendario = `${ item.reservationId.lead_traveler_name ? item.reservationId.lead_traveler_name : item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar` 
            }
            createFormEvent = {
              'summary': nombresCalendario,
              'location': `${detalles.pick_up_location} ${detalles.drop_off_location ? '- ' + detalles.drop_off_location: ''}`,
              'description': `INTER ${detalles.booking_reference}-${detalles.service_type}
${detalles.pax} Pax ${detalles.pax_kids ? detalles.pax_kids + ' Kids': ''} 
$ ${this.convertToMoney(detalles.tax ? detalles.tax + detalles.amount : detalles.amount)}
${detalles.arrival_airline} ${detalles.arrival_flight_no}
${detalles.arrival_time}

${detalles.phone}

${detalles.special_requirements ? detalles.special_requirements : ''}`,
              'start': {'dateTime': `${detalles.travel_date}T${detalles.arrival_time.substr(0,5)}:00-06:00`,
                      'timeZone': 'America/Costa_Rica'},
              'end': {'dateTime': `${this.$convertDateCalendarGoogle(detalles.travel_date, detalles.arrival_time, 3, '+')}`,
                      'timeZone': 'America/Costa_Rica'},
              'reminders': {'useDefault': true},
            }
          }

          else if (detalles.product_invoice_detailId &&
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' || 
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla' ||
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' ||
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' ||
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou' ||
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ||
            detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV'){
            console.log('entre a Tour')
            let detalle = ''
            let detalleScooter = ''
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV'){
              detalle = `506 ATV TOURS  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'){
              detalle = `LLANOS CORTES  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio'){
              detalle = `RIO CELESTE  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip'){
              detalle = `ZIP LINE  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra'){
              detalle = `TRASLADO  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'){
              detalle = `${detalles.product_invoice_detailId.short_name}  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''} `
            }
            if (detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco'){
              detalle = `SCOOTER  ${detalles.pax} Pax ${detalles.pax_kids ? '+': ''} ${detalles.pax_kids ? detalles.pax_kids + ' Kids' : ''}
${detalles.quantity} X $${detalles.rate} = $${this.convertToMoney(detalles.tax ? detalles.tax + detalles.amount : detalles.amount)}
START: ${detalles.travel_date}
END: ${detalles.departure_date}
DELIVER TIME: ${detalles.arrival_time}
${detalles.arrival_flight_no}`

            }
            let nombresCalendario = ''
            if (detalles.booking_reference.slice(0,7) == 'BR-506T'){
              nombresCalendario = `${detalles.traveler_names ? detalles.traveler_names: item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar`
            } else {
              nombresCalendario = `${ item.reservationId.lead_traveler_name ? item.reservationId.lead_traveler_name : item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar` 
            }
            createFormEvent = {
              'colorId': detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ? '6': 
                detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' ? '8' : 
                detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? '3' : '10',
              'summary': nombresCalendario,
              'location': `${detalles.pick_up_location} - ${detalles.drop_off_location}`,
              'description': `INTER ${detalles.booking_reference}-${detalles.service_type}
${detalle}
$ ${this.convertToMoney(detalles.tax ? detalles.tax + detalles.amount : detalles.amount)}
${detalles.arrival_time}

${detalles.phone}
${detalles.special_requirements ? detalles.special_requirements : ''}`,
            'start': {'dateTime': `${detalles.travel_date}T${detalles.arrival_time.substr(0,5)}:00-06:00`,
                      'timeZone': 'America/Costa_Rica'},
              'end': {'dateTime': `${this.$convertDateCalendarGoogle(detalles.travel_date, detalles.arrival_time, detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou' ? 10 : detalles.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? 1 : 3 , '+')}`,
                      'timeZone': 'America/Costa_Rica'},
              'reminders': {'useDefault': true},
            }
          }  
        } 

        else if (detalles.service_type == 'OUT'){
          if (detalles.product_invoice_detailId && detalles.product_invoice_detailId.short_name.slice(0,3) == 'R-T' 
            || detalles.product_invoice_detailId.short_name.slice(0,3) == 'One'){            
            let end_date_time = ''
            let nombresCalendario = ''
            if (detalles.booking_reference.slice(0,7) == 'BR-506T'){
              nombresCalendario = `${detalles.traveler_names ? detalles.traveler_names: item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar`
            } else {
              nombresCalendario = `${ item.reservationId.lead_traveler_name ? item.reservationId.lead_traveler_name : item.reservationId.customer_reservationId.first_name +' '+item.reservationId.customer_reservationId.last_name }  xAsignar` 
            }
            createFormEvent = {
                'colorId': '11',
                'summary': nombresCalendario,
                'location': `${detalles.pick_up_location} - ${detalles.drop_off_location}`,
                'description': `INTER ${detalles.booking_reference}-${detalles.service_type}
${detalles.pax} Pax ${detalles.pax_kids ? detalles.pax_kids + ' Kids': ''}  
$ ${this.convertToMoney(detalles.tax ? detalles.tax + detalles.amount : detalles.amount)}
${detalles.departure_airline} ${detalles.departure_flight_no}
${detalles.departure_time}

${detalles.phone}

${detalles.special_requirements ? detalles.special_requirements : ''}`,
                'start': {'dateTime': detalles.pick_up_departure_time ?  `${detalles.departure_date}T${detalles.pick_up_departure_time}:00-06:00` : `${this.$convertDateCalendarGoogle(detalles.departure_date, detalles.departure_time, 3, '-')}`,
                        'timeZone': 'America/Costa_Rica'},
                'end': {'dateTime': `${this.$convertDateCalendarGoogle(detalles.departure_date, detalles.departure_time, 1, '+')}`,
                        'timeZone': 'America/Costa_Rica'},
                'reminders': {'useDefault': true},
              }
          }
          
        }
  
        if (createFormEvent) {
          if (action == 'create') {
            createFormEvent = {form: createFormEvent, action: action, idlineaFactura: detalles.id}
            url = `extractor/api/506transfers/CrearEvento`
          }
          
          if (action == 'update') {
            createFormEvent = {id: detalles.calendar_operationId._id ,form: createFormEvent, action: action, idlineaFactura: detalles.id}
            url = `extractor/api/506transfers/ActualizarEvento`
          }

          if (action == 'delete') {
            createFormEvent = {id: detalles.calendar_operationId._id , action: action}
            url = `extractor/api/506transfers/BorrarEvento`
            this.selectedRecord = detalles.id
            this.createInvoiceDetailFormRecords = [{ id: detalles.id, calendar_operation: null }]
            this.summitForm('PUT', 'invoiceDetail', 'cerrar')

          }
          // console.log(createFormEvent)
          
          const respuesta = await this.$store.dispatch('verifyTokenPy')
          if(respuesta.state){
            axiosB(url, {
              method: "POST",
              data: createFormEvent,
              headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}` },
            })
            .then((result) => {
              this.createFormEvent = {}
              this.currentPage
              const size = 20
              this.getData(this.boards.folio, {page, size})
            }).catch((err) => {
              console.log(err.response)
              alert('Error al momento de ingresar evento en el calendario, \
Favor dirijase al boton editar y verifique que se cumplan todos los campos requeridos para el proceso. \
De persistir el error consulte al administrador')
              this.loadingTableInfoReservas = false
              // this.getData(this.boards.folio)
            })
          } else {
            this.$store.dispatch("logoutUser")
            .then(() => {
              this.$router.push({ name: "Login" })
            })
            .catch((err) => {
              alert(err)
            })
          }
          
        }
      } else {
        this.loadingTableInfoReservas = false
        this.$message({
          showClose: true,
          message: `Este Evento ya tiene calendario creado, favor revisar.`,
          type: "error",
        });
      }

    },
    invoicePdf(item, action){
      const url = `https://reports506.webdgroup.com/api/report`
      this.showButton = false
      let body = null
      
      if (item.invoice_details.length) {
        item.invoice_details.sort((a, b) => {
            let positionA = 0
            let positionB = 0

            if (a.travel_date) positionA = new Date(a.travel_date).getTime()
            if (a.departure_date) positionA = new Date(a.departure_date).getTime()
            if (b.travel_date) positionB = new Date(b.travel_date).getTime()
            if (b.departure_date) positionB = new Date(b.departure_date).getTime()
    
            if (positionA > positionB) {
                return 1
            } else if (positionA < positionB) {
                return -1
            }
        })
        
        //Registro hecho desde la unterfaz de viator
        if (item.reservationId.booking_reference.slice(0,7) != 'BR-506T' && item.reservationId.booking_reference.slice(0,6) != 'BR-WEB' ) {
          body = {
              template: { _id : "nEbhVdc9LSNsm9Rb"  },
              data : {
                  booking_confirmation: `FACT-${item.invoice_folio} - ${item.reservationId.booking_reference}`,
                  date: `${item.reservationId.travel_date}`,
                  due: item.invoice_folioId.due,
                  balance_due: item.invoice_folioId.balance_due,
                  client: `${item.reservationId.lead_traveler_name}`,
                  subtotal: `${item.reservationId.net_rate.split('$')[1]}`,
                  total: `${item.reservationId.net_rate.split('$')[1]}`,
                  paid: `${item.reservationId.payment_amount || '0.00'}`,
                  paid_date: `${item.reservationId.travel_date}`,
                  items: [],
                  containt_receipts: item.receipts ? true : false,
                  receipts: item.receipts
              },
              options: { reports: { save: false } }
          }
    
          let detail_amount = parseFloat(item.reservationId.net_rate.split('$')[1]) / item.invoice_details.length
    
          for (let [i, detail] of item.invoice_details.entries()) {
            let obj = {
                name: detail.traveler_names,
                description: {
                    date: `${detail.service_type == 'IN' ? detail.travel_date : detail.departure_date}`,
                    people_amount: `${detail.pax + ' Adults'} ${detail.pax_kids ? detail.pax_kids + ' Kids' : ''}`, 
                    // arrival_time: `${detail.service_type == 'IN' ?  detail.arrival_time : detail.departure_time}`,
                    pick_up: detail.pick_up_location,
                    drop_off: detail.drop_off_location,
                    special_requirements: detail.special_requirements
                },
                rate: `${detail.rate}`,
                qty: detail.quantity,
                amount: `${detail_amount.toFixed(2)}`,
                skip: false
            }

            if (i > 2 && i % 3 === 0) obj.skip = true
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Zip') obj.product_name = `ATV + ZIPLINE ${detail.service_type}`

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tra') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One') {
              if (detail.product_invoice_detailId.product_name) {
                obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
              } else {
                obj.product_name = `${detail.product_invoice_detailId.name.toUpperCase()} - ${detail.service_type}`
              }
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Lla') {
              obj.product_name = `LLANOS CORTES ${detail.service_type}`
              obj.description.tour_green = true
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Rio') {
              obj.product_name = `RÍO CELESTE ${detail.service_type}`
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'ATV') {
              obj.product_name = detail.product_invoice_detailId.product_name.toUpperCase()
              obj.description.tour_atv = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tou') {
              obj.product_name = detail.product_invoice_detailId.name
              body.data.policy_extra = true
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Sco') {
              obj.product_name = detail.product_invoice_detailId.product_name
              obj.policy_extra_moto = true
              obj.policy_text = detail.product_invoice_detailId.policy
              body.data.policy_extra_moto = true
              obj.description.departure_date = detail.departure_date
              obj.description.moto = detail.arrival_flight_no
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Otr') {
              obj.product_name = detail.product_invoice_detailId.short_name
              obj.description.moto = detail.arrival_flight_no
              obj.extra_otro = true
            }
            
            if (detail.service_type == 'IN' && detail.arrival_airline && detail.arrival_flight_no) {
              obj.description.flight = `${detail.arrival_airline} - ${detail.arrival_flight_no}`
            } else if (detail.service_type == 'OUT' && detail.departure_airline && detail.departure_flight_no) {
              obj.description.flight = `${detail.departure_airline} - ${detail.departure_flight_no}`

              if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One' || detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
                // let dateGoogle = this.convertDateCalendarGoogle(detail.departure_date, detail.departure_time, 3, '-')
                obj.description.pick_up_time = detail.pick_up_departure_time
              }
            }

            if (detail.service_type == 'IN') {
              obj.description.arrival_time = detail.arrival_time
            } else {
              obj.description.departure_time = detail.departure_time
            }

            body.data.items.push(obj)
          }
        } else {
          //Registro hecho manualmente
          body = {
            template: { _id : "nEbhVdc9LSNsm9Rb"  },
            data : {
              booking_confirmation: item.reservationId.booking_reference.slice(0,6) == 'BR-WEB' ? `FACT-${item.invoice_folio} - ${item.reservationId.booking_reference}` : `FACT-${item.invoice_folio} - BR-506T${item.reservationId.id}`,
              date: `${item.reservationId.final_date.substring(0, 10)}`,
              due: item.invoice_folioId.due,
              balance_due: 0,
              client: `${item.reservationId.payerId.first_name} ${item.reservationId.payerId.last_name}`.toUpperCase(),
              subtotal: 0,
              total: 0,
              paid: item.reservationId.payment_amount || 0,
              paid_date: `${item.reservationId.travel_date || ''}`,
              items: [],
              containt_receipts: item.receipts ? true : false,
              receipts: item.receipts
            },
            options: { reports: { save: false } }
          }
    
          for (let [i, detail] of item.invoice_details.entries()) {
            let obj = {
              name: detail.traveler_names,
              description: {
                date: `${detail.service_type == 'IN' ? detail.travel_date : detail.departure_date}`,
                people_amount: `${detail.pax + ' Adults'} ${detail.pax_kids ? detail.pax_kids + ' Kids' : ''}`,
                pick_up: detail.pick_up_location,
                drop_off: detail.drop_off_location,
                special_requirements: detail.special_requirements
              },
              rate: `${detail.rate}`,
              qty: detail.quantity,
              amount: `${this.convertToMoney(detail.amount)}`,
              skip: false
            }
            
            if (i > 2 && i % 3 === 0) obj.skip = true

            body.data.balance_due += detail.amount
            body.data.total += detail.amount
            body.data.subtotal += detail.amount
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Zip') obj.product_name = `ATV + ZIPLINE ${detail.service_type}`
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tra') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Lla') {
              obj.product_name = `LLANOS CORTES ${detail.service_type}`
              obj.description.tour_green = true
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Rio') {
              obj.product_name = `RÍO CELESTE ${detail.service_type}`
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'ATV') {
              obj.product_name = detail.product_invoice_detailId.product_name.toUpperCase()
              obj.description.tour_atv = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tou') {
              obj.product_name = detail.product_invoice_detailId.name
              body.data.policy_extra = true
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Sco') {
              obj.product_name = detail.product_invoice_detailId.product_name
              obj.policy_extra_moto = true
              obj.policy_text = detail.product_invoice_detailId.policy
              body.data.policy_extra_moto = true
              obj.description.departure_date = detail.departure_date
              obj.description.moto = detail.arrival_flight_no
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Otr') {
              obj.product_name = detail.product_invoice_detailId.short_name
              obj.description.moto = detail.arrival_flight_no
              obj.extra_otro = true
            }
            
            if (detail.service_type == 'IN' && detail.arrival_airline && detail.arrival_flight_no) {
              obj.description.flight = `${detail.arrival_airline} - ${detail.arrival_flight_no}`
            } else if (detail.service_type == 'OUT' && detail.departure_airline && detail.departure_flight_no) {
              obj.description.flight = `${detail.departure_airline} - ${detail.departure_flight_no}`

              if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One' || detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
                // let dateGoogle = this.convertDateCalendarGoogle(detail.departure_date, detail.departure_time, 3, '-')
                obj.description.pick_up_time = detail.pick_up_departure_time
              }
            }

            if (detail.service_type == 'IN') {
              obj.description.arrival_time = detail.arrival_time
            } else {
              obj.description.departure_time = detail.departure_time
            }

            body.data.items.push(obj)
          }
          
          body.data.balance_due = this.convertToMoney(body.data.balance_due - body.data.paid)
          body.data.paid = this.convertToMoney(body.data.paid)
          body.data.total = this.convertToMoney(body.data.total)
          body.data.subtotal = this.convertToMoney(body.data.subtotal)
        }
      }

      if (action) {
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YWx1Y2FyZG86QW5kMTE3MDAyMzIzNjE3QCE=`
          }
        })
        .then(res => {
          res.blob().then(file => {
            this.showButton = true
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        })
        .catch(err => {
          this.showButton = false
          console.error(err)
        })
      } else {
        return body
      }
    },
    calculoValorQty(item , type){
      if (type == 'IN'){
        this.arrivalAmountKey +=1
        this.createInvoiceDetailForm.arrival_amount = 0
        if(item && this.createInvoiceDetailForm.arrival_rate){
          this.createInvoiceDetailForm.arrival_amount = item * this.createInvoiceDetailForm.arrival_rate 
        } else {
          this.createInvoiceDetailForm.arrival_amount = 0
        }
      } else {
        this.departureAmountKey +=1
        this.createInvoiceDetailForm.departure_amount = 0
        if(item && this.createInvoiceDetailForm.departure_rate){
          this.createInvoiceDetailForm.departure_amount = item * this.createInvoiceDetailForm.departure_rate 
        } else {
          this.createInvoiceDetailForm.departure_amount = 0
        }
      }
    },
    calculoValor(item, type){
      if (type == 'IN') {
        this.arrivalAmountKey +=1
        this.createInvoiceDetailForm.arrival_amount = 0
        if(item && this.createInvoiceDetailForm.arrival_quantity){
          this.createInvoiceDetailForm.arrival_amount = item * this.createInvoiceDetailForm.arrival_quantity 
        } else {
          this.createInvoiceDetailForm.arrival_amount = 0
        }
      } else {
        this.departureAmountKey +=1
         this.createInvoiceDetailForm.departure_amount = 0
        if(item && this.createInvoiceDetailForm.departure_quantity){
          this.createInvoiceDetailForm.departure_amount = item * this.createInvoiceDetailForm.departure_quantity 
        } else {
          this.createInvoiceDetailForm.departure_amount = 0
        }
      } 
    },
    verificarSwitth(){
      if (this.swithServices == 'IN') {
        this.atv = false
        this.tour = false
        this.detailIn = true
        this.detailOut = false
        
        if (this.selectedProduct && this.selectedProduct.short_name.slice(0,7) == 'One Way') {
          // this.arrival_pickUp_locationKey +=1
          this.createInvoiceDetailForm.arrival_pickUp_location = 'Liberia Airport'
          this.disableDropOffLocation = false
          this.disablePickUpLocation = true
        }
        if (this.selectedProduct && this.selectedProduct.short_name == 'One Way SJO'){
          // this.arrival_pickUp_locationKey +=1
          this.createInvoiceDetailForm.arrival_pickUp_location = 'SJO Airport'  
          this.disableDropOffLocation = false
          this.disablePickUpLocation = true
        }
        
      } else {
        // console.log(this.swithServices, this.selectedProduct)
        this.detailIn = false
        this.detailOut = true
        this.atv = false
        this.tour = false
        if (this.selectedProduct && this.selectedProduct.short_name.slice(0,7) == 'One Way') {
          // this.departure_dropOff_locationKey +=1
          this.createInvoiceDetailForm.departure_dropOff_location = 'Liberia Airport'
          this.disablePickUpLocation = false
          this.disableDropOffLocation = true
        }
        if (this.selectedProduct && this.selectedProduct.short_name == 'One Way SJO') {
          // this.departure_dropOff_locationKey +=1
          this.createInvoiceDetailForm.departure_dropOff_location = 'SJO Airport'
          this.disablePickUpLocation = false
          this.disableDropOffLocation = true   
        } 
      }
    },
    async verificarCustomer(item){
      this.createInvoiceForm = {}
      await this.$store.dispatch('verifyToken')
      let buscarReserva = {
        url: `reservation/id/${item}`,
        method: 'GET',
        body: {},
        token: this.$store.state.accessToken 
      }
      // this.selectedReservation = this.reservations.find((f)=> f.id == item)
      this.selectedReservation = await this.getDataOne(buscarReserva)
      console.log(this.selectedReservation)
      if (this.selectedReservation){
        this.reservationDateKey +=1
        this.reservationDateKey1 +=1
        this.reservationDateKey2 +=1
        this.reservationBill_toKey +=1
        this.createInvoiceForm.date =  this.selectedReservation.start_date.substr(0, 10)
        this.createInvoiceForm.date_due =  this.selectedReservation.final_date.substr(0, 10)
        this.createInvoiceForm.bill_to =  this.selectedReservation.customer_reservation
        this.createInvoiceForm.bill_state =  'PENDIENTE'

      } else {
        this.createInvoiceForm.date =  null
        this.createInvoiceForm.date_due =  null
        this.createInvoiceForm.bill_to =  null
        this.createInvoiceForm.bill_state =  null
      }
      console.log(this.createInvoiceForm)
      

    },
    lookupTourOption(item) {
      this.saveContinue = true
      if (typeof item === 'object') {
        this.selectedProduct =  this.productEdit.find((product)=> product.id == item.product_invoice_detail)
      } else {
        this.selectedProduct =  this.productEdit.find((product)=> product.id == item)
      }      
      
      if (this.selectedProduct && this.selectedProduct.short_name.substring(0,6) == 'R-Trip'){
        if (!this.btnEdithVisible) {
          console.log('entre R-Trip')
          this.atv = false
          this.tour = false
          this.optionProductInOut = true
          this.detailIn = true
          this.detailOut = true
          this.visibleSwith = false
          this.otrosCargos = false
          this.scooter = false
        } else {
          if (item.service_type == 'IN'){
            this.atv = false
            this.tour = false
            this.optionProductInOut = false
            this.detailIn = true
            this.detailOut = false
            this.visibleSwith = false
            this.otrosCargos = false
            this.scooter = false
          } else {
            this.atv = false
            this.tour = false
            this.optionProductInOut = false
            this.detailIn = false
            this.detailOut = true
            this.visibleSwith = false
            this.otrosCargos = false
            this.scooter = false
          }
        }
      } else if (this.selectedProduct && this.selectedProduct.short_name.substring(0,7) == 'One Way'){
        console.log('One Way')
        this.atv = false
        this.tour = false
        this.detailIn = false
        this.detailOut = false
        this.optionProductInOut = false
        this.visibleSwith = true
        this.otrosCargos = false
        this.scooter = false
      } else if (this.selectedProduct && this.selectedProduct.short_name.substring(0,3) == 'ATV'){
        this.detailIn = true
        this.atv = true
        this.detailOut = false
        this.visibleSwith = false
        this.tour = false
        this.otrosCargos = false
         this.scooter = false
      } else if (this.selectedProduct && this.selectedProduct.short_name.substring(0,3) == 'Sco'){
        this.detailIn = true
        this.atv = true
        this.detailOut = false
        this.visibleSwith = false
        this.tour = false
        this.otrosCargos = false
        this.scooter = true
      } else if (this.selectedProduct && this.selectedProduct.short_name.substring(0,3) == 'Otr'){
        this.detailIn = true
        this.atv = false
        this.detailOut = false
        this.visibleSwith = false
        this.tour = false
        this.otrosCargos = true
        this.scooter = false
      }
       
      else  if (this.selectedProduct){
        this.tour = true
        this.detailIn = true
        this.detailOut = false
        this.visibleSwith = false
        this.otrosCargos = false
        this.scooter = false
      }
    },
    preSummit(){
      let lineaIn = {}
      let lineaOut = {}
      if (this.$refs.createInvoiceDetailForm.validate()){
        // console.log(this.createInvoiceDetailForm)
        this.isLoading = false
        // this.selectedProduct = this.product.find((product)=> product.id == item)
           
        if (this.selectedProduct && this.selectedProduct.short_name.substring(0,6) == 'R-Trip' && this.recentInvoice) {
          console.log('entre a rtrip')
          if (this.createInvoiceDetailForm.edit) {
            if (this.createInvoiceDetailForm.service_type == 'IN') {
              lineaIn.invoice = this.recentInvoice.id
              lineaIn.product_invoice_detail = this.selectedProduct.id
              if (this.createInvoiceDetailForm.edit) {
                lineaIn.service_type = this.createInvoiceDetailForm.service_type
              } else {
                lineaIn.service_type = 'IN'
              } 
              lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
              lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0 
              if (this.createInvoiceDetailForm.edit) {
                lineaIn.booking_reference = this.createInvoiceDetailForm.arrival_booking_reference
              } else {
                lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
              }
              lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
              lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
              lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
              lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
              lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
              lineaIn.arrival_flight_no = this.createInvoiceDetailForm.arrival_flight_no
              lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
              lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
              lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
              lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
              lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
              lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
              if (this.createInvoiceDetailForm.edit) lineaIn.id = this.createInvoiceDetailForm.id
              if (this.createInvoiceDetailForm.edit) lineaIn.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
              this.createInvoiceDetailFormRecords.push(lineaIn)
              lineaIn = {}
            } else {
              lineaOut.invoice = this.recentInvoice.id
              lineaOut.product_invoice_detail = this.selectedProduct.id
              if (this.createInvoiceDetailForm.edit) {
                lineaOut.service_type = this.createInvoiceDetailForm.service_type
              } else {
                lineaOut.service_type = 'OUT'
              }
              lineaOut.pax = parseInt(this.createInvoiceDetailForm.departure_pax)
              lineaOut.pax_kids = this.createInvoiceDetailForm.departure_pax_kids ? parseInt(this.createInvoiceDetailForm.departure_pax_kids) : 0
              if (this.createInvoiceDetailForm.edit) {
                lineaOut.booking_reference = this.createInvoiceDetailForm.departure_booking_reference
              } else {
                lineaOut.booking_reference = `BR-506T${this.selectedReservation.id}`
              }
              lineaOut.departure_date = this.createInvoiceDetailForm.departure_date
              lineaOut.departure_time = this.createInvoiceDetailForm.departure_time
              lineaOut.pick_up_departure_time = this.createInvoiceDetailForm.pick_up_departure_time
              lineaOut.pick_up_location = this.createInvoiceDetailForm.departure_pickUp_location
              lineaOut.drop_off_location = this.createInvoiceDetailForm.departure_dropOff_location
              lineaOut.departure_airline = this.createInvoiceDetailForm.departure_airline
              lineaOut.departure_flight_no = this.createInvoiceDetailForm.departure_flight_no
              lineaOut.quantity = parseFloat(this.createInvoiceDetailForm.departure_quantity)
              lineaOut.amount = parseFloat(this.createInvoiceDetailForm.departure_amount)
              lineaOut.rate = parseFloat(this.createInvoiceDetailForm.departure_rate)
              lineaOut.special_requirements = this.createInvoiceDetailForm.departure_special_requirements
              lineaOut.phone = this.createInvoiceDetailForm.departure_phone
              lineaOut.traveler_names = this.createInvoiceDetailForm.departure_traveler_names
              if (this.createInvoiceDetailForm.edit) lineaOut.id = this.createInvoiceDetailForm.id
              if (this.createInvoiceDetailForm.edit) lineaOut.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
              this.createInvoiceDetailFormRecords.push(lineaOut)
              lineaOut = {}
            }
          } else {

            lineaIn.invoice = this.recentInvoice.id
            lineaIn.product_invoice_detail = this.selectedProduct.id
            lineaIn.service_type = 'IN'
            lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
            lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0
            lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
            lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
            lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
            lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
            lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
            lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
            lineaIn.arrival_flight_no = this.createInvoiceDetailForm.arrival_flight_no
            lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
            lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
            lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
            lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
            lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
            lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
            lineaIn.id = this.createInvoiceDetailForm.id
            lineaIn.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
            this.createInvoiceDetailFormRecords.push(lineaIn)
            lineaIn = {}
            
            lineaOut.invoice = this.recentInvoice.id
            lineaOut.product_invoice_detail = this.selectedProduct.id
            lineaOut.service_type = 'OUT'
            lineaOut.pax = parseInt(this.createInvoiceDetailForm.departure_pax)
            lineaOut.pax_kids = this.createInvoiceDetailForm.departure_pax_kids ? parseInt(this.createInvoiceDetailForm.departure_pax_kids) : 0
            lineaOut.booking_reference = `BR-506T${this.selectedReservation.id}`
            lineaOut.departure_date = this.createInvoiceDetailForm.departure_date
            lineaOut.departure_time = this.createInvoiceDetailForm.departure_time
            lineaOut.pick_up_departure_time = this.createInvoiceDetailForm.pick_up_departure_time
            lineaOut.pick_up_location = this.createInvoiceDetailForm.departure_pickUp_location
            lineaOut.drop_off_location = this.createInvoiceDetailForm.departure_dropOff_location
            lineaOut.departure_airline = this.createInvoiceDetailForm.departure_airline
            lineaOut.departure_flight_no = this.createInvoiceDetailForm.departure_flight_no
            lineaOut.quantity = parseFloat(this.createInvoiceDetailForm.departure_quantity)
            lineaOut.amount = parseFloat(this.createInvoiceDetailForm.departure_amount)
            lineaOut.rate = parseFloat(this.createInvoiceDetailForm.departure_rate)
            lineaOut.special_requirements = this.createInvoiceDetailForm.departure_special_requirements
            lineaOut.phone = this.createInvoiceDetailForm.departure_phone
            lineaOut.traveler_names = this.createInvoiceDetailForm.departure_traveler_names
            lineaOut.id = this.createInvoiceDetailForm.id
            lineaOut.calendar_operation = this.createInvoiceDetailForm.calendar_operation
            this.createInvoiceDetailFormRecords.push(lineaOut)
            lineaOut = {}
          }
        }  
        else if (this.swithServices == 'IN' && this.selectedProduct.short_name.substring(0,7) == 'One Way' && this.recentInvoice){
          lineaIn.invoice = this.recentInvoice.id
          lineaIn.product_invoice_detail = this.selectedProduct.id
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.service_type = this.createInvoiceDetailForm.service_type
          } else {
            lineaIn.service_type = 'IN'
          } 
          lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
          lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0 
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.booking_reference = this.createInvoiceDetailForm.arrival_booking_reference
          } else {
            lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
          }
          lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
          lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
          lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
          lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
          lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
          lineaIn.arrival_flight_no = this.createInvoiceDetailForm.arrival_flight_no
          lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
          lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
          lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
          lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
          lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
          lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
          if (this.createInvoiceDetailForm.edit) lineaIn.id = this.createInvoiceDetailForm.id
          if (this.createInvoiceDetailForm.edit) lineaIn.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
          this.createInvoiceDetailFormRecords.push(lineaIn)
          lineaIn = {}
        }
        else if (this.swithServices == 'OUT' && this.selectedProduct.short_name.substring(0,7) == 'One Way' && this.recentInvoice) {
          lineaOut.invoice = this.recentInvoice.id
          lineaOut.product_invoice_detail = this.selectedProduct.id
          if (this.createInvoiceDetailForm.edit) {
            lineaOut.service_type = this.createInvoiceDetailForm.service_type
          } else {
            lineaOut.service_type = 'OUT'
          }
          lineaOut.pax = parseInt(this.createInvoiceDetailForm.departure_pax)
          lineaOut.pax_kids = this.createInvoiceDetailForm.departure_pax_kids ? parseInt(this.createInvoiceDetailForm.departure_pax_kids) : 0
          if (this.createInvoiceDetailForm.edit) {
            lineaOut.booking_reference = this.createInvoiceDetailForm.departure_booking_reference
          } else {
            lineaOut.booking_reference = `BR-506T${this.selectedReservation.id}`
          }
          lineaOut.departure_date = this.createInvoiceDetailForm.departure_date
          lineaOut.departure_time = this.createInvoiceDetailForm.departure_time
          lineaOut.pick_up_departure_time = this.createInvoiceDetailForm.pick_up_departure_time
          lineaOut.pick_up_location = this.createInvoiceDetailForm.departure_pickUp_location
          lineaOut.drop_off_location = this.createInvoiceDetailForm.departure_dropOff_location
          lineaOut.departure_airline = this.createInvoiceDetailForm.departure_airline
          lineaOut.departure_flight_no = this.createInvoiceDetailForm.departure_flight_no
          lineaOut.quantity = parseFloat(this.createInvoiceDetailForm.departure_quantity)
          lineaOut.amount = parseFloat(this.createInvoiceDetailForm.departure_amount)
          lineaOut.rate = parseFloat(this.createInvoiceDetailForm.departure_rate)
          lineaOut.special_requirements = this.createInvoiceDetailForm.departure_special_requirements
          lineaOut.phone = this.createInvoiceDetailForm.departure_phone
          lineaOut.traveler_names = this.createInvoiceDetailForm.departure_traveler_names
          if (this.createInvoiceDetailForm.edit) lineaOut.id = this.createInvoiceDetailForm.id
          if (this.createInvoiceDetailForm.edit) lineaOut.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
          this.createInvoiceDetailFormRecords.push(lineaOut)
          lineaOut = {}
        }
        else  if (this.selectedProduct.short_name.substring(0,3) == 'Sco' && this.recentInvoice) {          
          lineaIn.invoice = this.recentInvoice.id
          lineaIn.product_invoice_detail = this.selectedProduct.id
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.service_type = this.createInvoiceDetailForm.service_type
          } else {
            lineaIn.service_type = 'IN'
          }
          lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
          lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0 
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.booking_reference = this.createInvoiceDetailForm.arrival_booking_reference
          } else {
            lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
          }
          lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
          lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
          lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
          lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
          lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
          lineaIn.arrival_flight_no = JSON.stringify({motos: this.createInvoiceDetailForm.arrival_flight_no}) 
          lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
          lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
          lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
          lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
          lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
          lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
          lineaIn.departure_date = this.createInvoiceDetailForm.departure_date_deliver
          if (this.createInvoiceDetailForm.edit) lineaIn.id = this.createInvoiceDetailForm.id
          if (this.createInvoiceDetailForm.edit) lineaIn.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
          this.createInvoiceDetailFormRecords.push(lineaIn)
          
          lineaIn = {}
        }
         else  if (this.selectedProduct.short_name.substring(0,3) == 'Otr' && this.recentInvoice) {          
          lineaIn.invoice = this.recentInvoice.id
          lineaIn.product_invoice_detail = this.selectedProduct.id
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.service_type = this.createInvoiceDetailForm.service_type
          } else {
            lineaIn.service_type = 'IN'
          }
          // lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
          // lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0 
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.booking_reference = this.createInvoiceDetailForm.arrival_booking_reference
          } else {
            lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
          }
          lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
          // lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
          // lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
          // lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
          // lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
          lineaIn.arrival_flight_no = this.createInvoiceDetailForm.arrival_flight_no
          lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
          lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
          lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
          // lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
          // lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
          // lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
          // lineaIn.departure_date = this.createInvoiceDetailForm.departure_date_deliver
          if (this.createInvoiceDetailForm.edit) lineaIn.id = this.createInvoiceDetailForm.id
          this.createInvoiceDetailFormRecords.push(lineaIn)
          lineaIn = {}
        }
        else  if (this.selectedProduct && this.recentInvoice) {
          lineaIn.invoice = this.recentInvoice.id
          lineaIn.product_invoice_detail = this.selectedProduct.id
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.service_type = this.createInvoiceDetailForm.service_type
          } else {
            lineaIn.service_type = 'IN'
          }
          lineaIn.pax = parseInt(this.createInvoiceDetailForm.arrival_pax)
          lineaIn.pax_kids = this.createInvoiceDetailForm.arrival_pax_kids ? parseInt(this.createInvoiceDetailForm.arrival_pax_kids) : 0 
          if (this.createInvoiceDetailForm.edit) {
            lineaIn.booking_reference = this.createInvoiceDetailForm.arrival_booking_reference
          } else {
            lineaIn.booking_reference = `BR-506T${this.selectedReservation.id}`
          }
          lineaIn.travel_date = this.createInvoiceDetailForm.arrival_date
          lineaIn.arrival_time = this.createInvoiceDetailForm.arrival_time
          lineaIn.pick_up_location = this.createInvoiceDetailForm.arrival_pickUp_location
          lineaIn.drop_off_location = this.createInvoiceDetailForm.arrival_dropOff_location
          lineaIn.arrival_airline = this.createInvoiceDetailForm.arrival_airline
          lineaIn.arrival_flight_no = this.createInvoiceDetailForm.arrival_flight_no
          lineaIn.quantity = parseFloat(this.createInvoiceDetailForm.arrival_quantity)
          lineaIn.amount = parseFloat(this.createInvoiceDetailForm.arrival_amount)
          lineaIn.rate = parseFloat(this.createInvoiceDetailForm.arrival_rate)
          lineaIn.special_requirements = this.createInvoiceDetailForm.arrival_special_requirements
          lineaIn.phone = this.createInvoiceDetailForm.arrival_phone
          lineaIn.traveler_names = this.createInvoiceDetailForm.arrival_traveler_names
          if (this.atv) {
            if (this.createInvoiceDetailForm.edit && this.checkTotalvsSumaDetails){
              const answer = window.confirm(
                'Existe una diferencia entre el valor original y el nuevo valor de los detalles, \
                Desea reemplazar el valor original? \
                OK- Reemplaza el valor \
                CANCEL - Continua sin reemplazar.')
              if (answer){
                lineaIn.amount = parseFloat(this.sumatoriaPrecioDetalles)
                lineaIn.rate = parseFloat(this.sumatoriaPrecioDetalles)
                lineaIn.atv_detail =  {lineas: this.atvLines}
              } else {
                lineaIn.atv_detail =  {lineas: this.atvLines}
              }
            } else {
              lineaIn.atv_detail =  {lineas: this.atvLines}
            }
          }
          if (this.createInvoiceDetailForm.edit) lineaIn.id = this.createInvoiceDetailForm.id
          if (this.createInvoiceDetailForm.edit) lineaIn.calendar_operation = this.createInvoiceDetailForm.calendar_operation ? parseInt(this.createInvoiceDetailForm.calendar_operation) : null
          this.createInvoiceDetailFormRecords.push(lineaIn)
          lineaIn = {}
        }
        lineaIn = {}
        lineaOut = {}
        if (!this.createInvoiceDetailForm.edit) this.createInvoiceDetailForm = {}
        // if (!this.createInvoiceDetailForm.edit) this.$refs.createInvoiceDetailForm.resetValidation()
        // if (!this.createInvoiceDetailForm.edit) this.$refs.createInvoiceDetailForm.reset()
        this.isLoading = false
        this.saveContinue = false
        if (!this.createInvoiceDetailForm.edit) this.selectedProduct = null
        if (!this.createInvoiceDetailForm.edit) this.detailIn = false
        if (!this.createInvoiceDetailForm.edit) this.detailOut = false
        if (!this.createInvoiceDetailForm.edit) this.visibleSwith = false
        this.$notify({
          title: "Detalle Agregado",
          message: "Continue con el siguiente detalle",
          type: "success",
          duration: 6500,
        })

        return true
      } else {
        this.$message({
          showClose: true,
          message: `Revisar los campos con errores indicados`,
          type: "error",
          duration: 4500
        })
        this.isLoading = false
        return false
      }
      

    },
    async summitForm(method, board, option){
      console.log(this.createCustomerForm)
      this.loadingTableInfoReservas = true
      this.isLoading = true
      let error = false
      let form = {}
      if (board == 'invoiceDetail'){
        console.log('entre aqui summit')
        if (option != 'cerrar') {
          if (this.createInvoiceDetailFormRecords.length == 0 && !this.selectedProduct){
            this.$notify({
              title: "Sin detalles",
              message: "No se agregaron detalles a la factura",
              type: "info",
              duration: 5500,
            })
            this.isLoading = false
            if (this.$refs.createInvoiceDetailForm.validate()){
              this.closeInvoiceDetailForm()
              console.log('pase por aqui 1')
            }
  
          } else { 
            if (this.selectedProduct && this.selectedProduct.id){
              error = !this.preSummit()
              this.isLoading = false
            } else {
              if (this.$refs.createInvoiceDetailForm.validate()) {
                this.closeInvoiceDetailForm()
                console.log('pase por aqui 2')
              }
              console.log('ya puedes continuar a grabasr la factura')
            } 
          }
        }
        
        //armar el 
        form = {records: this.createInvoiceDetailFormRecords}
        board = 'invoice_detail'
        
      }

      if (board == this.boards.invoice) {
        if (!this.$refs.createInvoiceForm.validate()) {
          error = true
        } else {
          form = { reservation: method == 'PUT' ? this.selectedRecord : this.selectedReservation.id, ...this.createInvoiceForm }
        }
      }

      if (board == 'customer') {
        if (!this.$refs.createCustomerForm.validate()) {
          error = true
        } else {
          //Capitalize
          this.createCustomerForm.first_name = this.createCustomerForm.first_name[0].toUpperCase() + this.createCustomerForm.first_name.substring(1).toLowerCase()
          this.createCustomerForm.last_name = this.createCustomerForm.last_name[0].toUpperCase() + this.createCustomerForm.last_name.substring(1).toLowerCase()
          form = this.createCustomerForm
          
        }
      }

      if (!error) {
        console.log(board,form)
        await this.$store.dispatch('verifyToken')
        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((result) => {
            if (board == 'customer') {
              this.createInvoiceForm.bill_to = result.data.data.id
              
              this.getData(board)
              if (!this.btnEdithVisible) this.closeForm(board)
              if (this.btnEdithVisible) this.dialogCustomer = false
            }

            if (board == 'invoice') {
              this.isLoading = false
              const page = this.currentPage
              const size = 20
              this.getData(this.boards.folio, {page, size})
              this.recentInvoice = result.data.data
              this.openCreateInvoiceDetailsForm()
              this.$refs.createInvoiceForm.resetValidation()
              this.createInvoiceForm = {}
            }

            if (board == 'invoice_detail') {
              this.isLoading = false
              const page = this.currentPage
              const size = 20
              this.getData(this.boards.folio, {page, size})
              console.log('llegue y guarde y cerre')
              this.closeInvoiceDetailForm()
              this.createInvoiceDetailFormRecords = []
              this.atvLines = []
            }
          })
          .catch((err) => {
            console.log(err)
            alert(`error: ${err.response ? err.response.message : err}`)
            this.isLoading = false
          })
        } else if (method == 'PUT') {
          if (board == 'invoice_detail')  this.loadingTableInfoReservas = true 
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((result) => {
            if (board == 'invoice') {
              this.currentPage
              const size = 20
              this.getData(this.boards.folio, {page, size})
              this.isLoading = false
            }

            if (board == 'invoice_detail') {
              // this.loadingTableInfoReservas = false
              this.isLoading = false
              this.currentPage
              const size = 20
              this.getData(this.boards.folio, {page, size})
              console.info('SE MODIFICO Y LISTO')
              this.createInvoiceDetailFormRecords = []
            }

            this.closeForm(board, method)
          }).catch((err) => {
            console.log(err.response)
            alert(`error: ${err.response ? err.response.data.message : err}`)
            this.isLoading = false
            this.loadingTableInfoReservas = false
            this.btnEdithVisible = true
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    openCreateForm(board) {
      if (board == this.boards.invoice) this.dialogInvoice = true
      
      this.createInvoiceForm = {}
    },
    openCreateInvoiceDetailsForm(item, board){
      if (board == 'invoiceDetail'){
        // console.log(item, this.folio)
        this.dialogInfoInvoice = true
        this.dialogInvoice = false
        this.isLoading = false
        this.recentInvoice = item.invoice_folioId  
      } else {
        // console.log(item, this.folio)
        this.dialogInfoInvoice = true
        this.dialogInvoice = false
        this.isLoading = false
      }
    },
    openCreateDetail(){
      this.dialogInvoiceDetail = true
      this.visibleSwith = false
      this.selectedReservation = this.folio.find((f)=>  f.invoice_folio == this.recentInvoice.id )
    },
    closeDialogInfoReservas(){
      this.dialogInfoInvoice = false
    },
    closeCustomerSimpleForm() {
      this.dialogCustomer = false
      this.createCustomerForm = {}
    },
    closeInvoiceDetailForm() {
      this.dialogInvoiceDetail = false
      this.$refs.createInvoiceDetailForm.resetValidation()
      this.$refs.createInvoiceDetailForm.reset()
      this.createInvoiceDetailForm = {}
      this.$set(this.createInvoiceDetailForm, {})
      this.createInvoiceDetailFormRecords = []
      this.selectedProduct = null
      // this.selectedReservation = null
      this.recentInvoiceDetail = null
      // this.invoiceDetail = null
      this.disableDropOffLocation = false
      this.disablePickUpLocation = false
      this.atvLines = []
    },
    closeForm(board, method) {
      if (board == this.boards.invoice) {
        this.dialogInvoice = false
        this.$refs.createInvoiceForm.resetValidation()
        this.createInvoiceForm = {}
        this.btnEdithVisible = false
        this.disableDropOffLocation = false
        this.disablePickUpLocation = false
      }
      if (board == 'customer') {
        if (method != 'PUT') {
          this.closeCustomerSimpleForm()
          this.$refs.createCustomerForm.resetValidation()
          this.btnEdithVisible = false
        } else {
          this.dialogCustomer = false
        }
      }
      if (board == 'invoiceDetail') {
        let answer = null
        if (this.createInvoiceDetailFormRecords.length != 0 || Object.values(this.createInvoiceDetailForm).length != 0){
          if (method == 'PUT') {
            answer = true
          } else {
            answer =  window.confirm('Realmente deseas continuar? Tienes cambios sin guardar!')
          }
        } else {
          this.closeInvoiceDetailForm()
          this.optionProductInOut = false
          this.detailIn = false
          this.detailOut = false
          this.disableDropOffLocation = false
          this.disablePickUpLocation = false
        }
        if (answer){
          this.closeInvoiceDetailForm()
          this.optionProductInOut = false
          this.detailIn = false
          this.detailOut = false
          this.disableDropOffLocation = false
          this.disablePickUpLocation = false
        }
        this.btnEdithVisible = false
      }
      
      this.isLoading = false
      this.selectedRecord = null
      if (method == 'PUT') {
        this.dialogInvoiceDetail = false
      } 
    },
    //dialogo de crreacion de customer
    openCustomerSimpleForm() {
      this.dialogCustomer = true
    },


    //===EDIT
    async editItem(item, board){
      this.btnEdithVisible = true
      this.selectedRecord = item.invoice_folio
      if (item.reservationId.booking_reference.slice(0,7) != 'BR-506T'){
        this.typeReservation = true
        await this.$store.dispatch('verifyToken')
        axiosN506({
          method: 'GET',
          url: `reservation/id/${item.reservationId.id}`,
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          // data: body
        })
        .then((result) => {
          this.allReservation = []
          this.allReservation.push(result.data.data)
        }).catch((err) => {
          console.log(err.response)
        });
      } else {
        this.typeReservation = false
      }
      
      
      if (board == this.boards.invoice) {
        this.createInvoiceForm.reservation = item.reservationId.id
        this.createInvoiceForm.lead_traveler_name = item.reservationId.lead_traveler_name
        this.createInvoiceForm.id = item.invoice_folio
        this.createInvoiceForm.folio = item.id
        this.createInvoiceForm.date = item.invoice_folioId.date
        this.createInvoiceForm.date_due = item.invoice_folioId.date_due
        this.createInvoiceForm.bill_to = item.invoice_folioId.bill_to
        this.createInvoiceForm.bill_state = item.invoice_folioId.bill_state
        this.createInvoiceForm.balance_due = item.invoice_folioId.balance_due
        this.dialogInvoice = true
      }

      
    },

    editRowInvoiceDetail(detalles, item){
      this.btnEdithVisible = true
      this.dialogInvoiceDetail = true
      this.createInvoiceDetailForm = {}
      //hacer el before update

      //campos edit
      this.createInvoiceDetailForm.product_invoice_detail = detalles.product_invoice_detail
      this.createInvoiceDetailForm.edit = true
      this.createInvoiceDetailForm.service_type = detalles.service_type
      
      this.selectedRecord = detalles.id

      this.lookupTourOption(detalles)
      this.swithServices = detalles.service_type
      if (this.swithServices == 'IN') {
        this.detailIn = true
        this.createInvoiceDetailForm.id = detalles.id 
        
        this.createInvoiceDetailForm.arrival_amount = detalles.amount
        this.createInvoiceDetailForm.arrival_airline = detalles.arrival_airline 
        this.createInvoiceDetailForm.arrival_flight_no = detalles.arrival_flight_no  
        this.createInvoiceDetailForm.arrival_time = detalles.arrival_time 
        this.createInvoiceDetailForm.arrival_booking_reference = detalles.booking_reference 
        this.createInvoiceDetailForm.calendar_operation = detalles.calendar_operation 
        this.createInvoiceDetailForm.arrival_departure_airline = detalles.departure_airline 
        this.createInvoiceDetailForm.departure_date_deliver = detalles.departure_date 
        this.createInvoiceDetailForm.arrival_departure_flight_no = detalles.departure_flight_no 
        this.createInvoiceDetailForm.arrival_departure_time = detalles.departure_time 
        this.createInvoiceDetailForm.arrival_discount = detalles.discount 
        this.createInvoiceDetailForm.arrival_dropOff_location = detalles.drop_off_location 
        this.createInvoiceDetailForm.arrival_invoice = detalles.invoice 
        this.createInvoiceDetailForm.arrival_pax = detalles.pax 
        this.createInvoiceDetailForm.arrival_pax_kids = detalles.pax_kids 
        this.createInvoiceDetailForm.arrival_phone = detalles.phone 
        this.createInvoiceDetailForm.arrival_pickUp_location = detalles.pick_up_location 
        this.createInvoiceDetailForm.arrival_product_invoice_detail = detalles.product_invoice_detail 
        this.createInvoiceDetailForm.arrival_product_invoice_detailId = detalles.product_invoice_detailId 
        this.createInvoiceDetailForm.arrival_quantity = detalles.quantity 
        this.createInvoiceDetailForm.arrival_rate = detalles.booking_reference.slice(0,7) == 'BR-506T' ? detalles.rate : detalles.amount 
        this.createInvoiceDetailForm.arrival_special_requirements = detalles.special_requirements 
        this.createInvoiceDetailForm.arrival_tax = detalles.tax 
        this.createInvoiceDetailForm.arrival_date = detalles.travel_date 
        this.createInvoiceDetailForm.arrival_traveler_names = detalles.traveler_names 
        this.createInvoiceDetailForm.arrival_ttl_discount = detalles.ttl_discount 
        this.atvLines = detalles.atv_detail.lineas ? detalles.atv_detail.lineas : []

      } else {
        this.detailOut = true

        this.createInvoiceDetailForm.id = detalles.id 
        
        this.createInvoiceDetailForm.departure_amount = detalles.amount 
        this.createInvoiceDetailForm.departure_airline = detalles.departure_airline 
        this.createInvoiceDetailForm.departure_flight_no = detalles.departure_flight_no 
        this.createInvoiceDetailForm.departure_time = detalles.departure_time 
        this.createInvoiceDetailForm.pick_up_departure_time = detalles.pick_up_departure_time 
        this.createInvoiceDetailForm.departure_booking_reference = detalles.booking_reference 
        this.createInvoiceDetailForm.calendar_operation = detalles.calendar_operation 
        this.createInvoiceDetailForm.departure_airline = detalles.departure_airline 
        // this.createInvoiceDetailForm.departure_date_deliver = detalles.departure_date 
        this.createInvoiceDetailForm.departure_departure_flight_no = detalles.departure_flight_no 
        this.createInvoiceDetailForm.departure_departure_time = detalles.departure_time 
        this.createInvoiceDetailForm.departure_discount = detalles.discount 
        this.createInvoiceDetailForm.departure_dropOff_location = detalles.drop_off_location 
        this.createInvoiceDetailForm.departure_invoice = detalles.invoice 
        this.createInvoiceDetailForm.departure_pax = detalles.pax 
        this.createInvoiceDetailForm.departure_pax_kids = detalles.pax_kids 
        this.createInvoiceDetailForm.departure_phone = detalles.phone 
        this.createInvoiceDetailForm.departure_pickUp_location = detalles.pick_up_location 
        this.createInvoiceDetailForm.departure_product_invoice_detail = detalles.product_invoice_detail 
        this.createInvoiceDetailForm.departure_product_invoice_detailId = detalles.product_invoice_detailId 
        this.createInvoiceDetailForm.departure_quantity = detalles.quantity 
        this.createInvoiceDetailForm.departure_rate = detalles.booking_reference.slice(0,7) == 'BR-506T' ? detalles.rate : detalles.amount 
        this.createInvoiceDetailForm.departure_special_requirements = detalles.special_requirements 
        this.createInvoiceDetailForm.departure_tax = detalles.tax 
        this.createInvoiceDetailForm.departure_date = detalles.departure_date 
        this.createInvoiceDetailForm.departure_traveler_names = detalles.traveler_names 
        this.createInvoiceDetailForm.departure_ttl_discount = detalles.ttl_discount
      }
      
      
    },

    saveEdition(){
      if (this.preSummit()){
        this.summitForm('PUT', 'invoiceDetail', 'cerrar')
      } else {
        console.error('revisar campos a editar')
      }
    },


    //===DELETE
    deleteItemConfirm(board) {
      if (this.factura.invoice_folioId.bill_state != "PAGADA") {
        if (board == 'invoiceDetail') board = 'invoice_detail'
        this.isLoading = true
        this.loadingTableInfoReservas = true
        axiosN506(`${board}/delete`, {
          method: "DELETE",
          data: { id: this.selectedRecord.id, invoice: this.selectedRecord.invoice },
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then(() => {
          if (board == 'invoice_detail') {
            this.currentPage
            const size = 20
            this.getData(this.boards.folio, {page, size})
            console.info('SE borro Y LISTO')
            this.createInvoiceDetailFormRecords = []
            this.closeDelete(board)
          } else {
            this.getData(board)
            
            this.isLoading = false
          }
          
        }).catch((err) => {
          console.error(err)
          alert(`error: ${err.response ? err.response.data.message : err}`)
          this.isLoading = false          
        })

      }
      else {
        alert("Primero Cambie el estado de la Factura a PENDIENTE para que pueda ser borrado el registro")
      }
    },
    openDelete(detalles, board, item) {
      if (board == this.boards.invoice) {
        this.dialogDeleteProduct = true
        this.selectedRecord = detalles.id
      }
      if (board == 'invoiceDetail') {
        this.dialogDeleteInvoiceDetail = true
        this.selectedRecord = detalles
        this.factura = item
      }

    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.invoice) this.dialogDeleteProduct = false
      if (board == 'invoiceDetail' || board == 'invoice_detail') {
        this.dialogDeleteInvoiceDetail = false
      }
    },

    async deleteInvoiceWithFolio(item){
      this.loadingInvoiceTable = true
      console.log(item)
      const confirm = window.confirm(`Esta seguro de borrar el Invoice No. ${item.invoice_folio} y sus detalles?`)
      if (confirm){
        await this.$store.dispatch('verifyTokenPy')
        try {
          const response = await axiosN506({
            method: 'DELETE',
            url: `/folio/deleteInvoiceWfolio`,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
            data: {
              id_folio: item.id,
              id_invoice: item.invoice_folio
            }
          })
          this.currentPage
          const size = 20
          this.getData(this.boards.folio, {page, size})
          await this.$notify({
            title: "Record Eliminado",
            message: `${response.data.mensaje}`,
            type: "success",
            duration: 6500,
          })
          this.loadingInvoiceTable = false
        } catch (error) {
          this.loadingInvoiceTable = false
          console.log(error)
        }

      } else {
        this.loadingInvoiceTable = false
      }
    },

    //===METODOS VARIOS
    motosText(){

    },
    async getDataOnePy(obj){
      let dataResult = null
      try {
        await this.$store.dispatch('verifyTokenPy')
        const response = await axiosB({method: obj.method,url: obj.url,headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}` },data: obj.body })
        dataResult = response.data.data
        if (obj.text == 'reservations') this.reservations = response.data.data
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      }
      
      return dataResult
    },
    async getDataOne(obj){
      let dataResult = null
      try {
        
        const response = await axiosN506({method: obj.method,url: obj.url,headers: { Authorization: `Bearer ${obj.token}` },data: obj.body })
        dataResult = response.data.data
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      }
      
      return dataResult
    },
    async getData(board, pagination, form){
      let url = `${board}/list`
      let method = 'GET'
      let body = {}
      let obj = []

      if (board == 'folio') {
        url = `${board}/listWithDetails`
        if (pagination) {
          const {page, size} = pagination
          url += `/?page=${page}&size=${size}`
        }
        method = 'POST'
        body.records = obj
        body.historic = true
        body.form = form
      }

      this.loadingInvoiceTable = true
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: method,
        url: url,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        data: body
      })
      .then((result) => {
        if (board == this.boards.folio) {
          this.loadingInvoiceTable = false
          this.loadingTableInfoReservas = false
          this.isLoading = false

          this.currentPage = result.data.currentPage
          this.totalPages = result.data.totalPages-1

          this.invoice_records = result.data.datos.filter((item)=> {

            if (!item.invoice_folio){
              return false
            } else {
              let noCalendar = []
              for (let linea of item.invoice_details) {
                if (!linea.calendar_operationId){
                  noCalendar.push(linea.id)
                }
              }
              item.noCalendar = noCalendar

              noCalendar = []
              return true
            }
          })
          this.folio = result.data.datos
        }

        if (board == 'customer') this.customers = result.data.data
        if (board == 'product') {
          this.product = result.data.data.filter((item)=> item.product_name)
          this.productEdit = result.data.data
        }
        if (board == 'reservation') {
          this.reservations = result.data.data.filter((item)=> item.reservation_status <= 3)
        }
        this.loading = false
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      if (x) {
        return x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        return '0.00'
      }
    },
    itemTextfolio(item){
      let nombreCompleto = `${item.lead_traveler_name ? item.lead_traveler_name : item.first_name + ' ' + item.last_name}`
      return `${item.booking_reference ? item.booking_reference + ' ' + nombreCompleto: 'BR-506T'+item.id + ' ' + nombreCompleto}`
       
    },
    itemTextCustomer(item){
      return `${item.first_name} ${item.last_name}`
    },
    itemTextProduct(item){
      return `${item.product_name}`
    },
    itemTextProductEdit(item){
      return `${item.code} ${item.short_name}`
    },
    defaultHoraPickupDeparture(item){
      this.createInvoiceDetailForm.pick_up_departure_time = this.$convertDateCalendarGoogle(this.createInvoiceDetailForm.departure_date, item, 3, "-").slice(11, 16)
      
    },

    // startTimer () {
    //   this.timer = setInterval(() => {
    //     const page = this.currentPage
    //     const size = 20
    //     this.getData(this.boards.folio, {page, size})
    //   }, 32000)
    // },
    // // If you kill the timer before setTimeout callback has been executed the callback wont get executed
    // killTimer () {
    //   if (this.timer) {
    //     clearInterval(this.timer)
    //   }
    //   console.log(this.timer)
    // }
    chooseAtvType(item){
      this.dialogAtvDeteail = true
    },
    closeDialogAtvDetail(){
      this.dialogAtvDeteail = false
    },
    addLineAtvDetail(form){
      if (this.$refs.createFormAtvDetail.validate()){
        const index = this.atvLines.length == 0 ? 0 : this.atvLines[this.atvLines.length -1].index + 1
        this.atvLines.push({
          index: index,
          ...form
        })
        this.$refs.createFormAtvDetail.resetValidation()
        this.createFormAtvDetail = {}
      } else {
        alert('se debe especificar el tipo de vehiculo')
      }
    },
    deleteLineAtvDetail(line){
      this.atvLines.splice(this.atvLines.indexOf(line),1)
    },

    locationParameter(item, campo){
      console.log(campo)
      this.selectedLocationField = {field: campo}
      this.dialogLocation = true
      if (item){
        this.btnEditLocation = true
        this.createLocationForm.location = item
        this.selectedLocationField.id = this.location.find(loca=> loca.location == item).id
      } else {
        this.btnEditLocation = false
      }
    },

    async summitLocation(method){
      const body =   method == 'POST' ? this.createLocationForm : {id: this.selectedLocationField.id, ...this.createLocationForm}
      let url = method == 'POST' ? `/location/add`: `/location/update`
      this.isLoadingLocation = true
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: method,
        url: url,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        data: body
      })
      .then((result) => {
        this.getData('location', result.data.data.location)
        this.closeLocation()
      }).catch((err) => {
        this.isLoadingLocation = false
        console.log(err.response)
      });
    },
    closeLocation(){
      this.isLoadingLocation = false
      this.dialogLocation = false
      this.btnEditLocation = false
      this.createLocationForm.location = null
    }
  },
  created() {
    this.loadingInvoiceTable = true
    const page = this.currentPage
    const size = 20
    this.getData(this.boards.folio, {page, size})
    // this.startTimer()
    // this.getData('customer')
    this.getData('product')
    let Reservas = {
      url: `extractor/api/506transfers/reservationView`,
      method: 'GET',
      body: {},
      text: 'reservations'
    }
   this.getDataOnePy(Reservas)
   this.getData('customer')
  },
  mounted(){
    let data = this.$route.params.item
    if (data) this.invoiceSearch = data.booking_reference
  },
  beforeDestroy(){
    // this.killTimer()
  }
}
</script>

<style>
.campos-search .v-input__control .v-text-field__details{
  margin-bottom: 0 !important; 
}
</style>
<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        INVOICES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <HistoricInvoiceDataTable :headers="headers" :boards="boards">

       </HistoricInvoiceDataTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import HistoricInvoiceDataTable from '@/components/HistoricInvoiceDataTable' 
export default {
  name: 'Products',
  components: {
    HistoricInvoiceDataTable
  },
  data() {
    return {
      headers: {
        invoice: [
          {
            text: 'No. Fact',
            align: 'Center',
            value: 'invoice_folioId.id',
          },
          {
            text: 'Fecha',
            align: 'Center',
            value: 'invoice_folioId.date',
          },
          {
            text: 'Vencimiento',
            align: 'start',
            value: 'invoice_folioId.date_due',
          },
          {
            text: 'Estado',
            align: 'start',
            value: 'invoice_folioId.bill_state',
          },
          {
            text: 'Pagador',
            align: 'start',
            value: 'reservationId.lead_traveler_name',
          },
          {
            text: 'Booking Reference',
            align: 'center',
            value: 'reservationId.booking_reference',
          },
          {
            text: 'Balance Cuenta',
            align: 'right',
            value: 'invoice_folioId.balance_due',
          },
          {
            text: 'Due',
            align: 'center',
            value: 'invoice_folioId.due',
          },
          
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        busqueda:[
          {
            text: 'No. Fact',
            value: 'invoice',
          },
          // {
          //   text: 'Fecha',
          //   value: 'fecha',
          // },
          // {
          //   text: 'Vencimiento',
          //   value: 'invoice_folioId.date_due',
          // },
          // {
          //   text: 'Estado',
          //   value: 'estado',
          // },
          {
            text: 'Nombre',
            value: 'first_name',
          },
          {
            text: 'Apellido',
            value: 'last_name',
          },
          {
            text: 'Booking Reference',
            value: 'booking_reference',
            prefix: true,
            textPrefix: 'BR-'
          },
          // {
          //   text: 'Balance Cuenta',
          //   value: 'invoice_folioId.balance_due',
          // },
          // {
          //   text: 'Due',
          //   value: 'invoice_folioId.due',
          // },
        ]
      },
      boards: {
        invoice: 'invoice',
        folio: 'folio',
        invoice_details: 'invoice_details',
      }
    }
  },
}
</script>

<style>

</style>
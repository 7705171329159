<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        INVOICES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <InvoiceDataTable :headers="headers" :boards="boards">

       </InvoiceDataTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import InvoiceDataTable from '@/components/InvoiceDataTable.vue' 
export default {
  name: 'Products',
  components: {
    InvoiceDataTable
  },
  data() {
    return {
      headers: {
        invoice: [
          {
            text: 'No. Fact',
            align: 'Center',
            value: 'invoice_folioId.id',
          },
          {
            text: 'Fecha',
            align: 'Center',
            value: 'invoice_folioId.date',
          },
          {
            text: 'Vencimiento',
            align: 'start',
            value: 'invoice_folioId.date_due',
          },
          {
            text: 'Estado',
            align: 'start',
            value: 'invoice_folioId.bill_state',
          },
          {
            text: 'Pagador',
            align: 'start',
            value: 'reservationId.lead_traveler_name',
          },
          {
            text: 'Booking Reference',
            align: 'center',
            value: 'reservationId.booking_reference',
          },
          {
            text: 'Balance Cuenta',
            align: 'right',
            value: 'invoice_folioId.balance_due',
          },
          {
            text: 'Due',
            align: 'center',
            value: 'invoice_folioId.due',
          },
          
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        invoice: 'invoice',
        folio: 'folio',
        invoice_details: 'invoice_details',
      }
    }
  },
}
</script>

<style>

</style>